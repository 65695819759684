import type { SRN } from '@streamnative/unified-rbac/src/proto/condition'
import {
  view,
  User,
  update,
  create,
  remove,
  ServiceAccountBinding,
  ServiceAccount,
  APIKeys,
  Subscription,
  Product,
  CustomerPortalRequest,
  PulsarCluster,
  use,
  PulsarInstance,
  adminTenant,
  Secret,
  CloudEnvironment,
  CloudConnection,
  TenantWrapper,
  PulsarClusterWrapper,
  browse,
  PulsarInstanceWrapper,
  PoolOption,
  admin,
  PaymentIntent,
  Volume,
  Catalog,
  Unilink
} from '@/composables/useRbac'
import {
  CloudServiceaccountbindingsCreate,
  CloudServiceaccountbindingsAlter,
  CloudUsersAlter,
  CloudUsersCreate,
  CloudUsersDelete,
  CloudServiceaccountsDelete,
  CloudServiceaccountbindingsDelete,
  CloudServiceaccountsAlter,
  CloudApikeysDelete,
  CloudApikeysAlter,
  CloudApikeysCreate,
  BillingProductsDescribe,
  BillingCustomerportalrequestsCreate,
  CloudServiceaccountsCreate,
  CloudPulsarclustersDescribe,
  CloudUsersDescribe,
  CloudPulsarinstancesDescribe,
  CloudMetricsPulsarQuery,
  PulsarTenantsDescribe,
  PulsarNamespacesDescribe,
  PulsarTopicsDescribe,
  CloudServiceaccountsDescribe,
  CloudApikeysDescribe,
  CloudSecretsDescribe,
  PulsarSinksDescribe,
  PulsarSourcesDescribe,
  PulsarSinksCreate,
  PulsarSourcesCreate,
  PulsarSourcesAlter,
  PulsarSinksAlter,
  PulsarFunctionsDescribe,
  CloudPulsarclustersAlter,
  CloudCloudenvironmentsDescribe,
  CloudCloudconnectionsDescribe,
  CloudPulsarclustersCreate,
  CloudPulsarinstancesCreate,
  CloudPulsarinstancesAlter,
  CloudCloudconnectionsCreate,
  CloudCloudenvironmentsCreate,
  CloudPulsarinstancesDelete,
  CloudPulsarclustersDelete,
  PulsarTenantsCreate,
  PulsarTenantsDelete,
  type Permission,
  PulsarTenantsAlter,
  CloudSecretsCreate,
  CloudSecretsDelete,
  CloudSecretsAlter,
  CloudServiceaccountbindingsDescribe,
  PulsarNamespacesCreate,
  PulsarNamespacesAlter,
  PulsarNamespacesDelete,
  PulsarTopicsCreate,
  PulsarSourcesDelete,
  PulsarSinksDelete,
  PulsarFunctionsCreate,
  PulsarFunctionsDelete,
  PulsarFunctionsAlter,
  CloudCloudenvironmentsDelete,
  CloudCloudenvironmentsAlter,
  CloudCloudconnectionsAlter,
  CloudCloudconnectionsDelete,
  CloudPoolsDescribe,
  PulsarSubscriptionsDescribe,
  PulsarSubscriptionsCreate,
  PulsarSubscriptionsAlter,
  PulsarSubscriptionsDelete,
  PulsarTopicsDelete,
  PulsarTopicsAlter,
  PulsarMessagesProduce,
  PulsarMessagesConsume,
  PulsarBrokersDescribe,
  KsnSourcesDelete,
  KsnSinksDelete,
  KsnSinksAlter,
  KsnSourcesAlter,
  KsnSinksCreate,
  KsnSourcesCreate,
  KsnSinksDescribe,
  KsnSourcesDescribe,
  BillingPaymentintentsDescribe,
  BillingPaymentintentsCreate,
  BillingSubscriptionsDescribe,
  CloudSelfOrganizationsAlter,
  CloudUnilinkDescribe,
  CloudUnilinkCreate,
  CloudUnilinkAlter,
  CloudUnilinkDelete,
  CloudVolumesAlter,
  CloudVolumesCreate,
  CloudVolumesDelete,
  CloudVolumesDescribe,
  CloudCatalogsAlter,
  CloudCatalogsCreate,
  CloudCatalogsDelete,
  CloudCatalogsDescribe
} from '@/composables/useRbacV2'
import { can, type RbacQueryV2 } from '@/utils/rbacHelper'
import type { RouteLocationNormalized } from 'vue-router'

interface RbacStateParams {
  debug?: boolean
  to?: RouteLocationNormalized
  organization?: string
  instance?: string
  cluster?: string
  tenant?: string
  namespace?: string
  topic_name?: string
  topic_domain?: string
  subscription?: string
  connectorType?: 'sink' | 'source'
}

const AuthQueryLevelMap = {
  organization: 0,
  instance: 1,
  cluster: 2,
  tenant: 3,
  namespace: 4,
  topic: 5
}

const queryV2ItemFn = (
  permission: Permission,
  param?:
    | {
        srn?: SRN
        level?: 'organization' | 'instance' | 'cluster' | 'tenant' | 'namespace' | 'topic'
      }
    | undefined
) => {
  const { organization, instance, tenant, namespace, topic } = usePulsarState()
  const { activeClusterName } = useCluster()
  const { activeTopicDetail } = useTopic()
  return {
    permission,
    srn: {
      organization:
        param?.level && AuthQueryLevelMap[param.level] >= AuthQueryLevelMap.organization
          ? organization.value
          : '',
      instance:
        param?.level && AuthQueryLevelMap[param.level] >= AuthQueryLevelMap.instance
          ? instance.value
          : '',
      cluster:
        param?.level && AuthQueryLevelMap[param.level] >= AuthQueryLevelMap.cluster
          ? activeClusterName.value
          : '',
      tenant:
        param?.level && AuthQueryLevelMap[param.level] >= AuthQueryLevelMap.tenant
          ? tenant.value
          : '',
      namespace:
        param?.level && AuthQueryLevelMap[param.level] >= AuthQueryLevelMap.namespace
          ? namespace.value
          : '',
      topic_name:
        param?.level && AuthQueryLevelMap[param.level] >= AuthQueryLevelMap.topic
          ? topic.value
          : '',
      topic_domain: !topic.value
        ? undefined
        : param?.level && AuthQueryLevelMap[param.level] >= AuthQueryLevelMap.topic
        ? activeTopicDetail.value?.persistency
          ? 'persistent'
          : 'non-persistent'
        : '',
      ...(param?.srn || {})
    } as SRN
  }
}

const canAlterSelfOrganization = ({ debug }: RbacStateParams = {}) => {
  return can(
    {
      v2: queryV2ItemFn(CloudSelfOrganizationsAlter, { level: 'organization' })
    },
    debug
  )
}

const canDescribeBilling = ({ debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: {
        AND: [
          { action: view, subject: Product },
          { action: view, subject: Subscription }
        ]
      },
      v2: {
        AND: [
          queryV2ItemFn(BillingProductsDescribe, {
            level: 'organization'
          }),
          queryV2ItemFn(BillingSubscriptionsDescribe, {
            level: 'organization'
          })
        ]
      }
    },
    debug
  )
}

const canCreateBilling = ({ debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: {
        AND: [
          { action: create, subject: PaymentIntent },
          { action: create, subject: CustomerPortalRequest }
        ]
      },
      v2: {
        AND: [
          queryV2ItemFn(BillingPaymentintentsCreate, {
            level: 'organization'
          }),
          queryV2ItemFn(BillingCustomerportalrequestsCreate, {
            level: 'organization'
          })
        ]
      }
    },
    debug
  )
}

const canDescribePaymentIntent = ({ debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: {
        AND: [{ action: view, subject: PaymentIntent }]
      },
      v2: {
        AND: [
          queryV2ItemFn(BillingPaymentintentsDescribe, {
            level: 'organization'
          })
        ]
      }
    },
    debug
  )
}

const canCreatePaymentIntent = ({ debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: {
        AND: [{ action: create, subject: PaymentIntent }]
      },
      v2: {
        AND: [
          queryV2ItemFn(BillingPaymentintentsCreate, {
            level: 'organization'
          })
        ]
      }
    },
    debug
  )
}

const canDescribePoolOptionList = ({ debug }: RbacStateParams = {}) => {
  return can(
    {
      v2: queryV2ItemFn(CloudPoolsDescribe, {
        level: 'organization'
      }),
      v1: { action: view, subject: PoolOption }
    },
    debug
  )
}

const canDescribeSecretList = ({ debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: { action: view, subject: Secret },
      v2: queryV2ItemFn(CloudSecretsDescribe, {
        level: 'organization'
      })
    },
    debug
  )
}

const canDescribeSecret = (params: RbacStateParams = {}) => {
  return canDescribeSecretList(params)
}

const canCreateSecret = ({ debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: { action: create, subject: Secret },
      v2: queryV2ItemFn(CloudSecretsCreate, {
        level: 'organization'
      })
    },
    debug
  )
}

const canUpdateSecret = ({ debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: { action: update, subject: Secret },
      v2: queryV2ItemFn(CloudSecretsAlter, { level: 'organization' })
    },
    debug
  )
}

const canRemoveSecret = ({ debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: { action: remove, subject: Secret },
      v2: queryV2ItemFn(CloudSecretsDelete, { level: 'organization' })
    },
    debug
  )
}

const canDescribeUserList = ({ debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: { action: view, subject: User },
      v2: queryV2ItemFn(CloudUsersDescribe, { level: 'organization' })
    },
    debug
  )
}

const canDescribeUser = (params: RbacStateParams = {}) => {
  return canDescribeUserList(params)
}

const canCreateUser = ({ debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: {
        action: create,
        subject: User
      },
      v2: queryV2ItemFn(CloudUsersCreate, { level: 'organization' })
    },
    debug
  )
}

const canUpdateUser = ({ debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: {
        action: update,
        subject: User
      },
      v2: queryV2ItemFn(CloudUsersAlter, { level: 'organization' })
    },
    debug
  )
}

const canRemoveUser = ({ debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: {
        action: remove,
        subject: User
      },
      v2: queryV2ItemFn(CloudUsersDelete, { level: 'organization' })
    },
    debug
  )
}

const canDescribeSAList = ({ debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: { action: view, subject: ServiceAccount },
      v2: queryV2ItemFn(CloudServiceaccountsDescribe, { level: 'organization' })
    },
    debug
  )
}

const canDescribeSA = (params: RbacStateParams = {}) => {
  return canDescribeSAList(params)
}

const canCreateSA = ({ debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: {
        action: create,
        subject: ServiceAccount
      },
      v2: queryV2ItemFn(CloudServiceaccountsCreate, { level: 'organization' })
    },
    debug
  )
}

const canUpdateSA = ({ debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: {
        action: update,
        subject: ServiceAccount
      },
      v2: queryV2ItemFn(CloudServiceaccountsAlter, { level: 'organization' })
    },
    debug
  )
}

const canRemoveSA = ({ debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: {
        action: remove,
        subject: ServiceAccount
      },
      v2: queryV2ItemFn(CloudServiceaccountsDelete, { level: 'organization' })
    },
    debug
  )
}

const canDescribeSABindingList = ({ debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: {
        action: view,
        subject: ServiceAccountBinding
      },
      v2: queryV2ItemFn(CloudServiceaccountbindingsDescribe, { level: 'organization' })
    },
    debug
  )
}

const canDescribeSABinding = (params: RbacStateParams = {}) => {
  return canDescribeSABindingList(params)
}

const canCreateSABinding = ({ debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: {
        action: create,
        subject: ServiceAccountBinding
      },
      v2: queryV2ItemFn(CloudServiceaccountbindingsCreate, { level: 'organization' })
    },
    debug
  )
}

const canUpdateSABinding = ({ debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: { action: update, subject: ServiceAccountBinding },
      v2: queryV2ItemFn(CloudServiceaccountbindingsAlter, { level: 'organization' })
    },
    debug
  )
}

const canRemoveSABinding = ({ debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: {
        action: remove,
        subject: ServiceAccountBinding
      },
      v2: queryV2ItemFn(CloudServiceaccountbindingsDelete, { level: 'organization' })
    },
    debug
  )
}

const canDescribeApiKeyList = ({ debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: { action: view, subject: APIKeys },
      v2: queryV2ItemFn(CloudApikeysDescribe, { level: 'organization' })
    },
    debug
  )
}

const canDescribeApiKey = (params: RbacStateParams = {}) => {
  return canDescribeApiKeyList(params)
}

const canCreateAPIKey = ({ debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: {
        action: create,
        subject: APIKeys
      },
      v2: queryV2ItemFn(CloudApikeysCreate, { level: 'organization' })
    },
    debug
  )
}

const canUpdateAPIKey = ({ debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: {
        action: update,
        subject: APIKeys
      },
      v2: queryV2ItemFn(CloudApikeysAlter, { level: 'organization' })
    },
    debug
  )
}

const canRemoveAPIKey = ({ debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: {
        action: remove,
        subject: APIKeys
      },
      v2: queryV2ItemFn(CloudApikeysDelete, { level: 'organization' })
    },
    debug
  )
}

const canDescribeInstanceList = ({ debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: {
        OR: [
          { action: view, subject: PulsarInstance },
          { action: browse, subject: PulsarInstance }
        ]
      },
      v2: queryV2ItemFn(CloudPulsarinstancesDescribe, { level: 'organization' })
    },
    debug
  )
}

const canDescribeInstance = ({ instance: _instance, debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: {
        OR: [
          { action: view, subject: PulsarInstance },
          { action: browse, subject: PulsarInstance }
        ]
      },
      v2: queryV2ItemFn(CloudPulsarinstancesDescribe, {
        level: 'instance',
        srn: _instance ? ({ instance: _instance } as SRN) : undefined
      })
    },
    debug
  )
}

const canCreateInstance = ({ debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: { action: create, subject: PulsarInstance },
      v2: queryV2ItemFn(CloudPulsarinstancesCreate, { level: 'organization' })
    },
    debug
  )
}

const canUpdateInstance = ({ instance, debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: { action: update, subject: PulsarInstance },
      v2: queryV2ItemFn(CloudPulsarinstancesAlter, {
        level: 'instance',
        srn: instance ? ({ instance } as SRN) : undefined
      })
    },
    debug
  )
}

const canRemoveInstance = ({ instance, debug }: { instance?: string; debug?: any } = {}) => {
  return can(
    {
      v1: { action: remove, subject: PulsarInstance },
      v2: queryV2ItemFn(CloudPulsarinstancesDelete, {
        level: 'instance',
        srn: instance ? ({ instance: instance } as SRN) : undefined
      })
    },
    debug
  )
}

const canDescribeClusterList = ({ instance, debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: {
        OR: [
          { action: view, subject: PulsarCluster },
          { action: browse, subject: PulsarCluster }
        ]
      },
      v2: queryV2ItemFn(CloudPulsarclustersDescribe, {
        level: 'instance',
        srn: instance ? ({ instance } as SRN) : undefined
      })
    },
    debug
  )
}

const canDescribeCluster = ({ cluster, debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: {
        OR: [
          { action: view, subject: PulsarCluster },
          { action: browse, subject: PulsarCluster }
        ]
      },
      v2: queryV2ItemFn(CloudPulsarclustersDescribe, {
        level: 'cluster',
        srn: cluster ? ({ cluster } as SRN) : undefined
      })
    },
    debug
  )
}

const canCreateCluster = ({ debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: { action: create, subject: PulsarCluster },
      v2: queryV2ItemFn(CloudPulsarclustersCreate, {
        level: 'instance'
      })
    },
    debug
  )
}

const canUpdateCluster = ({ cluster, debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: { action: update, subject: PulsarCluster },
      v2: queryV2ItemFn(CloudPulsarclustersAlter, {
        level: 'cluster',
        srn: cluster ? ({ cluster } as SRN) : undefined
      })
    },
    debug
  )
}

const canRemoveCluster = ({ cluster, debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: { action: remove, subject: PulsarCluster },
      v2: queryV2ItemFn(CloudPulsarclustersDelete, {
        level: 'cluster',
        srn: cluster ? ({ cluster } as SRN) : undefined
      })
    },
    debug
  )
}

const canDescribePulsarMetrics = ({ cluster, debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: {
        OR: [
          { action: view, subject: PulsarCluster },
          { action: browse, subject: PulsarCluster }
        ]
      },
      v2: queryV2ItemFn(CloudMetricsPulsarQuery, {
        level: 'cluster',
        srn: cluster ? ({ cluster } as SRN) : undefined
      })
    },
    debug
  )
}

const canDescribeTenantList = ({ debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: {
        OR: [
          { action: adminTenant, subject: PulsarCluster },
          { action: use, subject: PulsarCluster },
          { action: browse, subject: PulsarCluster }
        ]
      },
      v2: queryV2ItemFn(PulsarTenantsDescribe, { level: 'cluster' })
    },
    debug
  )
}

const canDescribeTenant = ({ tenant, debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: {
        OR: [
          { action: adminTenant, subject: PulsarCluster },
          { action: use, subject: PulsarCluster },
          { action: browse, subject: PulsarCluster }
        ]
      },
      v2: queryV2ItemFn(PulsarTenantsDescribe, {
        level: 'tenant',
        srn: tenant ? ({ tenant } as SRN) : undefined
      })
    },
    debug
  )
}

const canCreateTenant = ({ debug }: RbacStateParams = {}) => {
  const { instance } = usePulsarState()
  const { activeClusterName } = useCluster()
  return (
    can(
      {
        v1: { action: use, subject: PulsarCluster },
        v2: queryV2ItemFn(PulsarTenantsCreate, { level: 'cluster' })
      },
      debug
    ) ||
    can(
      {
        v1: {
          AND: [
            {
              action: adminTenant,
              subject: new PulsarInstanceWrapper({ metadata: { name: instance.value as string } })
            },
            {
              action: adminTenant,
              subject: new PulsarClusterWrapper({
                metadata: { name: activeClusterName.value as string }
              })
            }
          ]
        },
        v2: queryV2ItemFn(PulsarTenantsCreate, { level: 'cluster' })
      },
      debug
    )
  )
}

const canUpdateTenant = ({ tenant: _tenant, debug }: RbacStateParams = {}) => {
  const { tenant, instance } = usePulsarState()
  const { activeClusterName } = useCluster()
  return (
    can(
      {
        v1: { action: use, subject: PulsarCluster },
        v2: queryV2ItemFn(PulsarTenantsAlter, {
          level: 'tenant',
          srn: _tenant ? ({ tenant: _tenant } as SRN) : undefined
        })
      },
      debug
    ) ||
    can({
      v1: {
        action: adminTenant,
        subject: new TenantWrapper(
          _tenant || (tenant.value as string),
          instance.value as string,
          activeClusterName.value as string
        )
      },
      v2: queryV2ItemFn(PulsarTenantsAlter, {
        level: 'tenant',
        srn: _tenant ? ({ tenant: _tenant } as SRN) : undefined
      })
    })
  )
}

const canRemoveTenant = ({ tenant: _tenant, debug }: RbacStateParams = {}) => {
  const { tenant, instance } = usePulsarState()
  const { activeClusterName } = useCluster()
  return (
    can(
      {
        v1: { action: use, subject: PulsarCluster },
        v2: queryV2ItemFn(PulsarTenantsDelete, {
          level: 'tenant',
          srn: _tenant ? ({ tenant: _tenant } as SRN) : undefined
        })
      },
      debug
    ) ||
    can({
      v1: {
        action: adminTenant,
        subject: new TenantWrapper(
          _tenant || (tenant.value as string),
          instance.value as string,
          activeClusterName.value as string
        )
      },
      v2: queryV2ItemFn(PulsarTenantsDelete, {
        level: 'tenant',
        srn: _tenant ? ({ tenant: _tenant } as SRN) : undefined
      })
    })
  )
}

const canDescribeNamespaceList = ({ debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: {
        OR: [
          { action: adminTenant, subject: PulsarCluster },
          { action: use, subject: PulsarCluster },
          { action: browse, subject: PulsarCluster }
        ]
      },
      v2: queryV2ItemFn(PulsarNamespacesDescribe, { level: 'tenant' })
    },
    debug
  )
}

const canDescribeNamespace = ({ namespace, debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: {
        OR: [
          { action: adminTenant, subject: PulsarCluster },
          { action: use, subject: PulsarCluster },
          { action: browse, subject: PulsarCluster }
        ]
      },
      v2: queryV2ItemFn(PulsarNamespacesDescribe, {
        level: 'namespace',
        srn: namespace ? ({ namespace } as SRN) : undefined
      })
    },
    debug
  )
}

const canCreateNamespace = ({ tenant: _tenant, debug }: RbacStateParams = {}) => {
  const { tenant, instance } = usePulsarState()
  const { activeClusterName } = useCluster()
  return (
    can(
      {
        v1: { action: use, subject: PulsarCluster },
        v2: queryV2ItemFn(PulsarNamespacesCreate, { level: 'tenant' })
      },
      debug
    ) ||
    can(
      {
        v1: {
          action: adminTenant,
          subject: new TenantWrapper(
            _tenant || (tenant.value as string),
            instance.value as string,
            activeClusterName.value as string
          )
        },
        v2: queryV2ItemFn(PulsarNamespacesCreate, { level: 'tenant' })
      },
      debug
    )
  )
}

const canUpdateNamespace = ({ namespace, debug }: RbacStateParams = {}) => {
  const { tenant, instance } = usePulsarState()
  const { activeClusterName } = useCluster()
  return (
    can(
      {
        v1: { action: use, subject: PulsarCluster },
        v2: queryV2ItemFn(PulsarNamespacesAlter, {
          level: 'namespace',
          srn: namespace ? ({ namespace } as SRN) : undefined
        })
      },
      debug
    ) ||
    can({
      v1: {
        action: adminTenant,
        subject: new TenantWrapper(
          tenant.value as string,
          instance.value as string,
          activeClusterName.value as string
        )
      },
      v2: queryV2ItemFn(PulsarNamespacesAlter, {
        level: 'namespace',
        srn: namespace ? ({ namespace } as SRN) : undefined
      })
    })
  )
}

const canRemoveNamespace = ({ namespace, debug }: RbacStateParams = {}) => {
  const { tenant, instance } = usePulsarState()
  const { activeClusterName } = useCluster()
  return (
    can(
      {
        v1: { action: use, subject: PulsarCluster },
        v2: queryV2ItemFn(PulsarNamespacesDelete, {
          level: 'namespace',
          srn: namespace ? ({ namespace } as SRN) : undefined
        })
      },
      debug
    ) ||
    can({
      v1: {
        action: adminTenant,
        subject: new TenantWrapper(
          tenant.value as string,
          instance.value as string,
          activeClusterName.value as string
        )
      },
      v2: queryV2ItemFn(PulsarNamespacesDelete, {
        level: 'namespace',
        srn: namespace ? ({ namespace } as SRN) : undefined
      })
    })
  )
}

const canDescribeTopicList = ({ debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: {
        OR: [
          { action: adminTenant, subject: PulsarCluster },
          { action: use, subject: PulsarCluster },
          { action: browse, subject: PulsarCluster }
        ]
      },
      v2: queryV2ItemFn(PulsarTopicsDescribe, { level: 'namespace' })
    },
    debug
  )
}

const canDescribeTopic = ({ topic_domain, topic_name, debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: {
        OR: [
          { action: adminTenant, subject: PulsarCluster },
          { action: use, subject: PulsarCluster },
          { action: browse, subject: PulsarCluster }
        ]
      },
      v2: queryV2ItemFn(PulsarTopicsDescribe, {
        level: 'topic',
        srn: topic_domain && topic_name ? ({ topic_domain, topic_name } as SRN) : undefined
      })
    },
    debug
  )
}

const canCreateTopic = ({
  tenant: _tenant,
  namespace: _namespace,
  debug
}: RbacStateParams = {}) => {
  const { tenant, instance, namespace } = usePulsarState()
  const { activeClusterName } = useCluster()
  return (
    can(
      {
        v1: { action: use, subject: PulsarCluster },
        v2: queryV2ItemFn(PulsarTopicsCreate, {
          level: 'namespace',
          srn: {
            tenant: _tenant || (tenant.value as string),
            namespace: _namespace || (namespace.value as string)
          } as SRN
        })
      },
      debug
    ) ||
    can(
      {
        v1: {
          action: adminTenant,
          subject: new TenantWrapper(
            _tenant || (tenant.value as string),
            instance.value as string,
            activeClusterName.value as string
          )
        },
        v2: queryV2ItemFn(PulsarTopicsCreate, {
          level: 'namespace',
          srn: {
            tenant: _tenant || (tenant.value as string),
            namespace: _namespace || (namespace.value as string)
          } as SRN
        })
      },
      debug
    )
  )
}

const canUpdateTopic = ({ topic_domain, topic_name, debug }: RbacStateParams = {}) => {
  const { tenant, instance } = usePulsarState()
  const { activeClusterName } = useCluster()
  return (
    can(
      {
        v1: { action: use, subject: PulsarCluster },
        v2: queryV2ItemFn(PulsarTopicsAlter, {
          level: 'topic',
          srn: topic_domain && topic_name ? ({ topic_domain, topic_name } as SRN) : undefined
        })
      },
      debug
    ) ||
    can({
      v1: {
        action: adminTenant,
        subject: new TenantWrapper(
          tenant.value as string,
          instance.value as string,
          activeClusterName.value as string
        )
      },
      v2: queryV2ItemFn(PulsarTopicsAlter, {
        level: 'topic',
        srn: topic_domain && topic_name ? ({ topic_domain, topic_name } as SRN) : undefined
      })
    })
  )
}

const canRemoveTopic = ({ topic_domain, topic_name, debug }: RbacStateParams = {}) => {
  const { tenant, instance } = usePulsarState()
  const { activeClusterName } = useCluster()
  return (
    can(
      {
        v1: { action: use, subject: PulsarCluster },
        v2: queryV2ItemFn(PulsarTopicsDelete, {
          level: 'topic',
          srn: topic_domain && topic_name ? ({ topic_domain, topic_name } as SRN) : undefined
        })
      },
      debug
    ) ||
    can({
      v1: {
        action: adminTenant,
        subject: new TenantWrapper(
          tenant.value as string,
          instance.value as string,
          activeClusterName.value as string
        )
      },
      v2: queryV2ItemFn(PulsarTopicsDelete, {
        level: 'topic',
        srn: topic_domain && topic_name ? ({ topic_domain, topic_name } as SRN) : undefined
      })
    })
  )
}

const canProduceMessages = ({ topic_domain, topic_name, debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: {
        OR: [
          { action: adminTenant, subject: PulsarCluster },
          { action: use, subject: PulsarCluster },
          { action: browse, subject: PulsarCluster }
        ]
      },
      v2: queryV2ItemFn(PulsarMessagesProduce, {
        level: 'topic',
        srn: topic_domain && topic_name ? ({ topic_domain, topic_name } as SRN) : undefined
      })
    },
    debug
  )
}

const canConsumeMessages = ({ topic_domain, topic_name, debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: {
        OR: [
          { action: adminTenant, subject: PulsarCluster },
          { action: use, subject: PulsarCluster },
          { action: browse, subject: PulsarCluster }
        ]
      },
      v2: queryV2ItemFn(PulsarMessagesConsume, {
        level: 'topic',
        srn: topic_domain && topic_name ? ({ topic_domain, topic_name } as SRN) : undefined
      })
    },
    debug
  )
}

const canDescribeTopicBroker = ({ topic_domain, topic_name, debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: {
        OR: [
          { action: adminTenant, subject: PulsarCluster },
          { action: use, subject: PulsarCluster },
          { action: browse, subject: PulsarCluster }
        ]
      },
      v2: queryV2ItemFn(PulsarBrokersDescribe, {
        level: 'topic',
        srn: topic_domain && topic_name ? ({ topic_domain, topic_name } as SRN) : undefined
      })
    },
    debug
  )
}

const canDescribeTopicSubscriptionList = ({ debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: {
        OR: [
          { action: adminTenant, subject: PulsarCluster },
          { action: use, subject: PulsarCluster },
          { action: browse, subject: PulsarCluster }
        ]
      },
      v2: queryV2ItemFn(PulsarSubscriptionsDescribe, { level: 'topic' })
    },
    debug
  )
}

const canDescribeTopicSubscription = ({
  topic_domain,
  topic_name,
  subscription,
  debug
}: RbacStateParams = {}) => {
  return can(
    {
      v1: {
        OR: [
          { action: adminTenant, subject: PulsarCluster },
          { action: use, subject: PulsarCluster },
          { action: browse, subject: PulsarCluster }
        ]
      },
      v2: queryV2ItemFn(PulsarSubscriptionsDescribe, {
        level: 'topic',
        srn: {
          ...(topic_domain && topic_name ? { topic_domain, topic_name } : {}),
          ...(subscription ? { subscription } : {})
        } as SRN
      })
    },
    debug
  )
}

const canCreateTopicSubscription = ({
  tenant: _tenant,
  namespace: _namespace,
  topic_domain,
  topic_name,
  debug
}: RbacStateParams = {}) => {
  const { tenant, instance, namespace } = usePulsarState()
  const { activeClusterName } = useCluster()
  return (
    can(
      {
        v1: { action: use, subject: PulsarCluster },
        v2: queryV2ItemFn(PulsarSubscriptionsCreate, {
          level: 'topic',
          srn: {
            tenant: _tenant || (tenant.value as string),
            namespace: _namespace || (namespace.value as string),
            ...(topic_domain && topic_name ? { topic_domain, topic_name } : {})
          } as SRN
        })
      },
      debug
    ) ||
    can(
      {
        v1: {
          action: adminTenant,
          subject: new TenantWrapper(
            _tenant || (tenant.value as string),
            instance.value as string,
            activeClusterName.value as string
          )
        },
        v2: queryV2ItemFn(PulsarSubscriptionsCreate, {
          level: 'topic',
          srn: {
            tenant: _tenant || (tenant.value as string),
            namespace: _namespace || (namespace.value as string),
            ...(topic_domain && topic_name ? { topic_domain, topic_name } : {})
          } as SRN
        })
      },
      debug
    )
  )
}

const canUpdateTopicSubscription = ({
  topic_domain,
  topic_name,
  subscription,
  debug
}: RbacStateParams = {}) => {
  const { tenant, instance } = usePulsarState()
  const { activeClusterName } = useCluster()
  return (
    can(
      {
        v1: { action: use, subject: PulsarCluster },
        v2: queryV2ItemFn(PulsarSubscriptionsAlter, {
          level: 'topic',
          srn: {
            ...(topic_domain && topic_name ? { topic_domain, topic_name } : {}),
            ...(subscription ? { subscription } : {})
          } as SRN
        })
      },
      debug
    ) ||
    can({
      v1: {
        action: adminTenant,
        subject: new TenantWrapper(
          tenant.value as string,
          instance.value as string,
          activeClusterName.value as string
        )
      },
      v2: queryV2ItemFn(PulsarSubscriptionsAlter, {
        level: 'topic',
        srn: {
          ...(topic_domain && topic_name ? { topic_domain, topic_name } : {}),
          ...(subscription ? { subscription } : {})
        } as SRN
      })
    })
  )
}

const canRemoveTopicSubscription = ({
  topic_domain,
  topic_name,
  subscription,
  debug
}: RbacStateParams = {}) => {
  const { tenant, instance } = usePulsarState()
  const { activeClusterName } = useCluster()
  return (
    can(
      {
        v1: { action: use, subject: PulsarCluster },
        v2: queryV2ItemFn(PulsarSubscriptionsDelete, {
          level: 'topic',
          srn: {
            ...(topic_domain && topic_name ? { topic_domain, topic_name } : {}),
            ...(subscription ? { subscription } : {})
          } as SRN
        })
      },
      debug
    ) ||
    can(
      {
        v1: {
          action: adminTenant,
          subject: new TenantWrapper(
            tenant.value as string,
            instance.value as string,
            activeClusterName.value as string
          )
        },
        v2: queryV2ItemFn(PulsarSubscriptionsDelete, {
          level: 'topic',
          srn: {
            ...(topic_domain && topic_name ? { topic_domain, topic_name } : {}),
            ...(subscription ? { subscription } : {})
          } as SRN
        })
      },
      debug
    )
  )
}

const canDescribeConnectorList = ({ to, connectorType, debug }: RbacStateParams = {}) => {
  let v2: RbacQueryV2 = {
    OR: [
      queryV2ItemFn(PulsarSinksDescribe, { level: 'cluster' }),
      queryV2ItemFn(PulsarSourcesDescribe, { level: 'cluster' })
    ]
  }
  if (to?.params?.type === 'sink' || connectorType === 'sink') {
    v2 = { AND: [queryV2ItemFn(PulsarSinksDescribe, { level: 'cluster' })] }
  } else if (to?.params?.type === 'source' || connectorType === 'source') {
    v2 = { AND: [queryV2ItemFn(PulsarSourcesDescribe, { level: 'cluster' })] }
  }
  return can(
    {
      v1: {
        OR: [
          { action: view, subject: PulsarCluster },
          { action: browse, subject: PulsarCluster }
        ]
      },
      v2
    },
    debug
  )
}

const canDescribeConnector = (params: RbacStateParams = {}) => {
  return canDescribeConnectorList(params)
}

const canCreateConnector = ({ to, connectorType, debug }: RbacStateParams = {}) => {
  let v2: RbacQueryV2 = {
    OR: [
      queryV2ItemFn(PulsarSinksCreate, { level: 'cluster' }),
      queryV2ItemFn(PulsarSourcesCreate, { level: 'cluster' })
    ]
  }
  if (to?.params?.type === 'sinks' || connectorType === 'sink') {
    v2 = { AND: [queryV2ItemFn(PulsarSinksCreate, { level: 'cluster' })] }
  } else if (to?.params?.type === 'sources' || connectorType === 'source') {
    v2 = { AND: [queryV2ItemFn(PulsarSourcesCreate, { level: 'cluster' })] }
  }
  return can(
    {
      v1: { action: update, subject: PulsarCluster },
      v2
    },
    debug
  )
}

const canUpdateConnector = ({ to, connectorType, debug }: RbacStateParams = {}) => {
  let v2: RbacQueryV2 = {
    OR: [
      queryV2ItemFn(PulsarSinksAlter, { level: 'cluster' }),
      queryV2ItemFn(PulsarSourcesAlter, { level: 'cluster' })
    ]
  }
  if (to?.params?.type === 'sinks' || connectorType === 'sink') {
    v2 = { AND: [queryV2ItemFn(PulsarSinksAlter, { level: 'cluster' })] }
  } else if (to?.params?.type === 'sources' || connectorType === 'source') {
    v2 = { AND: [queryV2ItemFn(PulsarSourcesAlter, { level: 'cluster' })] }
  }
  return can(
    {
      v1: { action: update, subject: PulsarCluster },
      v2
    },
    debug
  )
}

const canRemoveConnector = ({ to, connectorType, debug }: RbacStateParams = {}) => {
  let v2: RbacQueryV2 = {
    OR: [
      queryV2ItemFn(PulsarSinksDelete, { level: 'cluster' }),
      queryV2ItemFn(PulsarSourcesDelete, { level: 'cluster' })
    ]
  }
  if (to?.params?.type === 'sinks' || connectorType === 'sink') {
    v2 = { AND: [queryV2ItemFn(PulsarSinksDelete, { level: 'cluster' })] }
  } else if (to?.params?.type === 'sources' || connectorType === 'source') {
    v2 = { AND: [queryV2ItemFn(PulsarSourcesDelete, { level: 'cluster' })] }
  }
  return can(
    {
      v1: { action: update, subject: PulsarCluster },
      v2
    },
    debug
  )
}

const canDescribeKsnConnectorList = ({ to, connectorType, debug }: RbacStateParams = {}) => {
  let v2: RbacQueryV2 = {
    OR: [
      queryV2ItemFn(KsnSinksDescribe, { level: 'cluster' }),
      queryV2ItemFn(KsnSourcesDescribe, { level: 'cluster' })
    ]
  }
  v2 = { AND: [queryV2ItemFn(CloudPulsarclustersDescribe, { level: 'cluster' })] }
  // TODO: open these code after the RBAC for ksn is ready from the backend side
  // if (to?.params?.type === 'sink' || connectorType === 'sink') {
  //   v2 = { AND: [queryV2ItemFn(KsnSinksDescribe, { level: 'cluster' })] }
  // } else if (to?.params?.type === 'source' || connectorType === 'source') {
  //   v2 = { AND: [queryV2ItemFn(KsnSourcesDescribe, { level: 'cluster' })] }
  // }
  return can(
    {
      v1: {
        OR: [
          { action: view, subject: PulsarCluster },
          { action: browse, subject: PulsarCluster }
        ]
      },
      v2
    },
    debug
  )
}

const canDescribeKsnConnector = (params: RbacStateParams = {}) => {
  return canDescribeKsnConnectorList(params)
}

const canCreateKsnConnector = ({ to, connectorType, debug }: RbacStateParams = {}) => {
  let v2: RbacQueryV2 = {
    OR: [
      queryV2ItemFn(KsnSinksCreate, { level: 'cluster' }),
      queryV2ItemFn(KsnSourcesCreate, { level: 'cluster' })
    ]
  }
  v2 = { AND: [queryV2ItemFn(CloudPulsarclustersAlter, { level: 'cluster' })] }
  // TODO: open these code after the RBAC for ksn is ready from the backend side
  // if (to?.params?.type === 'sinks' || connectorType === 'sink') {
  //   v2 = { AND: [queryV2ItemFn(KsnSinksCreate, { level: 'cluster' })] }
  // } else if (to?.params?.type === 'sources' || connectorType === 'source') {
  //   v2 = { AND: [queryV2ItemFn(KsnSourcesCreate, { level: 'cluster' })] }
  // }
  return can(
    {
      v1: { action: update, subject: PulsarCluster },
      v2
    },
    debug
  )
}

const canUpdateKsnConnector = ({ to, connectorType, debug }: RbacStateParams = {}) => {
  let v2: RbacQueryV2 = {
    OR: [
      queryV2ItemFn(KsnSinksAlter, { level: 'cluster' }),
      queryV2ItemFn(KsnSourcesAlter, { level: 'cluster' })
    ]
  }
  v2 = { AND: [queryV2ItemFn(CloudPulsarclustersAlter, { level: 'cluster' })] }
  // TODO: open these code after the RBAC for ksn is ready from the backend side
  // if (to?.params?.type === 'sinks' || connectorType === 'sink') {
  //   v2 = { AND: [queryV2ItemFn(KsnSinksAlter, { level: 'cluster' })] }
  // } else if (to?.params?.type === 'sources' || connectorType === 'source') {
  //   v2 = { AND: [queryV2ItemFn(KsnSourcesAlter, { level: 'cluster' })] }
  // }
  return can(
    {
      v1: { action: update, subject: PulsarCluster },
      v2
    },
    debug
  )
}

const canRemoveKsnConnector = ({ to, connectorType, debug }: RbacStateParams = {}) => {
  let v2: RbacQueryV2 = {
    OR: [
      queryV2ItemFn(KsnSourcesDelete, { level: 'cluster' }),
      queryV2ItemFn(KsnSinksDelete, { level: 'cluster' })
    ]
  }
  v2 = { AND: [queryV2ItemFn(CloudPulsarclustersAlter, { level: 'cluster' })] }
  // TODO: open these code after the RBAC for ksn is ready from the backend side
  // if (to?.params?.type === 'sinks' || connectorType === 'sink') {
  //   v2 = { AND: [queryV2ItemFn(KsnSourcesDelete, { level: 'cluster' })] }
  // } else if (to?.params?.type === 'sources' || connectorType === 'source') {
  //   v2 = { AND: [queryV2ItemFn(KsnSourcesDelete, { level: 'cluster' })] }
  // }
  return can(
    {
      v1: { action: update, subject: PulsarCluster },
      v2
    },
    debug
  )
}

const canDescribeFunctionList = ({ debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: { action: view, subject: PulsarCluster },
      v2: queryV2ItemFn(PulsarFunctionsDescribe, { level: 'cluster' })
    },
    debug
  )
}

const canDescribeFunction = (params: RbacStateParams = {}) => {
  return canDescribeFunctionList(params)
}

const canCreateFunction = ({ debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: { action: update, subject: PulsarCluster },
      v2: queryV2ItemFn(PulsarFunctionsCreate, { level: 'cluster' })
    },
    debug
  )
}

const canUpdateFunction = ({ debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: { action: update, subject: PulsarCluster },
      v2: queryV2ItemFn(PulsarFunctionsAlter, { level: 'cluster' })
    },
    debug
  )
}

const canRemoveFunction = ({ debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: { action: update, subject: PulsarCluster },
      v2: queryV2ItemFn(PulsarFunctionsDelete, { level: 'cluster' })
    },
    debug
  )
}

const canDescribePfSqlList = (params: RbacStateParams = {}) => {
  return canDescribeFunctionList(params)
}

const canDescribePfSql = (params: RbacStateParams = {}) => {
  return canDescribeFunction(params)
}

const canCreatePfSql = (params: RbacStateParams = {}) => {
  return canCreateFunction(params)
}

const canUpdatePfSql = (params: RbacStateParams = {}) => {
  return canUpdateFunction(params)
}

const canRemovePfSql = (params: RbacStateParams = {}) => {
  return canRemoveFunction(params)
}

const canDescribeCloudEnvironmentList = ({ debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: { action: view, subject: CloudEnvironment },
      v2: queryV2ItemFn(CloudCloudenvironmentsDescribe, { level: 'organization' })
    },
    debug
  )
}

const canDescribeCloudEnvironment = (params: RbacStateParams = {}) => {
  return canDescribeCloudEnvironmentList(params)
}

const canCreateCloudEnvironment = ({ debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: { action: create, subject: CloudEnvironment },
      v2: queryV2ItemFn(CloudCloudenvironmentsCreate, { level: 'organization' })
    },
    debug
  )
}

const canUpdateCloudEnvironment = ({ debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: { action: update, subject: CloudEnvironment },
      v2: queryV2ItemFn(CloudCloudenvironmentsAlter, { level: 'organization' })
    },
    debug
  )
}

const canRemoveCloudEnvironment = ({ debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: { action: remove, subject: CloudEnvironment },
      v2: queryV2ItemFn(CloudCloudenvironmentsDelete, { level: 'organization' })
    },
    debug
  )
}

const canDescribeCloudConnectionList = ({ debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: { action: view, subject: CloudConnection },
      v2: queryV2ItemFn(CloudCloudconnectionsDescribe, { level: 'organization' })
    },
    debug
  )
}

const canDescribeCloudConnection = (params: RbacStateParams = {}) => {
  return canDescribeCloudConnectionList(params)
}

const canCreateCloudConnection = ({ debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: { action: create, subject: CloudConnection },
      v2: queryV2ItemFn(CloudCloudconnectionsCreate, { level: 'organization' })
    },
    debug
  )
}

const canUpdateCloudConnection = ({ debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: { action: update, subject: CloudConnection },
      v2: queryV2ItemFn(CloudCloudconnectionsAlter, { level: 'organization' })
    },
    debug
  )
}

const canRemoveCloudConnection = ({ debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: { action: remove, subject: CloudConnection },
      v2: queryV2ItemFn(CloudCloudconnectionsDelete, { level: 'organization' })
    },
    debug
  )
}

const canDescribeVolumeList = ({ debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: { action: view, subject: Volume },
      v2: queryV2ItemFn(CloudVolumesDescribe, { level: 'organization' })
    },
    debug
  )
}

const canDescribeCloudVolume = (params: RbacStateParams = {}) => {
  return canDescribeVolumeList(params)
}

const canCreateVolume = ({ debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: { action: create, subject: Volume },
      v2: queryV2ItemFn(CloudVolumesCreate, { level: 'organization' })
    },
    debug
  )
}

const canUpdateVolume = ({ debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: { action: update, subject: Volume },
      v2: queryV2ItemFn(CloudVolumesAlter, { level: 'organization' })
    },
    debug
  )
}

const canRemoveVolume = ({ debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: { action: remove, subject: Volume },
      v2: queryV2ItemFn(CloudVolumesDelete, { level: 'organization' })
    },
    debug
  )
}

const canDescribeCatalogList = ({ debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: { action: view, subject: Catalog },
      v2: queryV2ItemFn(CloudCatalogsDescribe, { level: 'organization' })
    },
    debug
  )
}

const canDescribeCatalog = (params: RbacStateParams = {}) => {
  return canDescribeCatalogList(params)
}

const canCreateCatalog = ({ debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: { action: create, subject: Catalog },
      v2: queryV2ItemFn(CloudCatalogsCreate, { level: 'organization' })
    },
    debug
  )
}

const canUpdateCatalog = ({ debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: { action: update, subject: Catalog },
      v2: queryV2ItemFn(CloudCatalogsAlter, { level: 'organization' })
    },
    debug
  )
}

const canRemoveCatalog = ({ debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: { action: remove, subject: Catalog },
      v2: queryV2ItemFn(CloudCatalogsDelete, { level: 'organization' })
    },
    debug
  )
}

const canDescribeUnilinkList = ({ debug }: RbacStateParams = {}) => {
  return can({
    v1: { action: view, subject: Unilink },
    v2: queryV2ItemFn(CloudUnilinkDescribe, { level: 'organization' })
  })
}

const canDescribeUnilink = (params: RbacStateParams = {}) => {
  return canDescribeUnilinkList(params)
}

const canCreateUnilink = ({ debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: { action: create, subject: Unilink },
      v2: queryV2ItemFn(CloudUnilinkCreate, { level: 'organization' })
    },
    debug
  )
}

const canUpdateUnilink = ({ debug }: RbacStateParams = {}) => {
  return can(
    {
      v1: { action: update, subject: Unilink },
      v2: queryV2ItemFn(CloudUnilinkAlter, { level: 'organization' })
    },
    debug
  )
}

export const canRemoveUnilink = ({ debug }: RbacStateParams = {}) => {
  return can({
    v1: { action: remove, subject: Unilink },
    v2: queryV2ItemFn(CloudUnilinkDelete, { level: 'organization' })
  })
}

export const rbacManager = () => {
  return {
    queryV2ItemFn,

    canAlterSelfOrganization,

    canDescribeBilling,
    canCreateBilling,
    canDescribePaymentIntent,

    canDescribePoolOptionList,

    canDescribeSecretList,
    canDescribeSecret,
    canCreateSecret,
    canUpdateSecret,
    canRemoveSecret,

    canDescribeUserList,
    canDescribeUser,
    canCreateUser,
    canRemoveUser,
    canUpdateUser,

    canDescribeSAList,
    canDescribeSA,
    canCreateSA,
    canUpdateSA,
    canRemoveSA,

    canDescribeSABindingList,
    canDescribeSABinding,
    canCreateSABinding,
    canUpdateSABinding,
    canRemoveSABinding,

    canDescribeApiKeyList,
    canDescribeApiKey,
    canCreateAPIKey,
    canUpdateAPIKey,
    canRemoveAPIKey,

    canDescribeInstanceList,
    canDescribeInstance,
    canCreateInstance,
    canUpdateInstance,
    canRemoveInstance,

    canDescribeClusterList,
    canDescribeCluster,
    canCreateCluster,
    canUpdateCluster,
    canRemoveCluster,

    canDescribePulsarMetrics,

    canDescribeTenantList,
    canDescribeTenant,
    canCreateTenant,
    canUpdateTenant,
    canRemoveTenant,

    canDescribeNamespaceList,
    canDescribeNamespace,
    canCreateNamespace,
    canUpdateNamespace,
    canRemoveNamespace,

    canDescribeTopicList,
    canDescribeTopic,
    canCreateTopic,
    canUpdateTopic,
    canRemoveTopic,

    canProduceMessages,
    canConsumeMessages,

    canDescribeTopicBroker,

    canDescribeTopicSubscriptionList,
    canDescribeTopicSubscription,
    canCreateTopicSubscription,
    canUpdateTopicSubscription,
    canRemoveTopicSubscription,

    canDescribeConnectorList,
    canDescribeConnector,
    canCreateConnector,
    canUpdateConnector,
    canRemoveConnector,

    canDescribeKsnConnectorList,
    canDescribeKsnConnector,
    canCreateKsnConnector,
    canUpdateKsnConnector,
    canRemoveKsnConnector,

    canDescribeFunctionList,
    canDescribeFunction,
    canCreateFunction,
    canUpdateFunction,
    canRemoveFunction,

    canDescribePfSqlList,
    canDescribePfSql,
    canCreatePfSql,
    canUpdatePfSql,
    canRemovePfSql,

    canDescribeCloudEnvironment,
    canDescribeCloudEnvironmentList,
    canCreateCloudEnvironment,
    canUpdateCloudEnvironment,
    canRemoveCloudEnvironment,

    canDescribeCloudConnectionList,
    canDescribeCloudConnection,
    canCreateCloudConnection,
    canUpdateCloudConnection,
    canRemoveCloudConnection,

    canDescribeUnilinkList,
    canDescribeUnilink,
    canCreateUnilink,
    canUpdateUnilink,
    canRemoveUnilink,

    canDescribeVolumeList,
    canDescribeCloudVolume,
    canCreateVolume,
    canUpdateVolume,
    canRemoveVolume,

    canDescribeCatalogList,
    canDescribeCatalog,
    canCreateCatalog,
    canUpdateCatalog,
    canRemoveCatalog
  }
}
