import { auth } from '@/auth'
import { forEach } from 'lodash-es'
import {
  AbilityBuilder,
  type AbilityTuple,
  type MatchConditions,
  PureAbility,
  type SubjectRawRule,
  type SubjectType
} from '@casl/ability'
import { useMemoize } from '@vueuse/core'
import { fetchSelfSubjectRulesReview } from '@/api/rbac'
import type {
  V1alpha1PulsarInstance,
  V1alpha1ResourceRule
} from '@streamnative/cloud-api-client-typescript'
import { tenantsAdminApiIsAvailable, usePluginsApi } from '@/composables/pulsarAdmin'
import { type Cluster, useCluster } from '@/composables/useCluster'

export type action =
  | 'view'
  | 'create'
  | 'delete'
  | 'update'
  | 'browse'
  | 'admin'
  | 'watch'
  | 'use'
  | 'admin-tenant'

export const view: action = 'view'
export const create: action = 'create'
export const update: action = 'update'
export const browse: action = 'browse'
export const remove: action = 'delete' // because delete is a keyword
export const use: action = 'use'
export const admin: action = 'admin'
export const adminTenant: action = 'admin-tenant'

export const subjects = [
  'customerportalrequests',
  'organizations',
  'paymentintents',
  'publicoffers',
  'pulsarclusters',
  'pulsarinstances',
  'products',
  'secrets',
  'selfregistrations',
  'serviceaccounts',
  'apikeys',
  'serviceaccountbindings',
  'setupintents',
  'subscriptions',
  'subscriptionintents',
  'tenants',
  'users',
  'pooloptions',
  'cloudconnections',
  'cloudenvironments',
  'volumes',
  'catalogs',
  'unilinks'
] as const

// To allow for iteration over the subjects
export type Subject = (typeof subjects)[number]
export const CustomerPortalRequest: Subject = 'customerportalrequests'
export const Organization: Subject = 'organizations'
// TODO double check types that can use paymentintent
export const PaymentIntent: Subject = 'paymentintents'
export const PublicOffer: Subject = 'publicoffers'
export const PulsarCluster: Subject = 'pulsarclusters'
export const PulsarInstance: Subject = 'pulsarinstances'
// TODO double check on roles that can use this type
export const Product: Subject = 'products'
export const ServiceAccount: Subject = 'serviceaccounts'
export const APIKeys: Subject = 'apikeys'
export const Secret: Subject = 'secrets'
export const SelfRegistration: Subject = 'selfregistrations'
export const ServiceAccountBinding: Subject = 'serviceaccountbindings'
// TODO double check on roles that can use this type
export const SetupIntent: Subject = 'setupintents'
// TODO double check on roles that can use this type
export const Subscription: Subject = 'subscriptions'
// TODO double check on roles that can use this type
export const SubscriptionIntent: Subject = 'subscriptionintents'
export const Tenant: Subject = 'tenants'
export const User: Subject = 'users'
// TODO we need to verify pooloptions come back from selfsubjectuserreviews
export const PoolOption: Subject = 'pooloptions'

export const CloudConnection: Subject = 'cloudconnections'
export const CloudEnvironment: Subject = 'cloudenvironments'

export const Volume: Subject = 'volumes'
export const Catalog: Subject = 'catalogs'
export const Unilink: Subject = 'unilinks'

const verbActions: Record<string, action> = {
  admin: 'admin',
  '*': 'admin',
  get: 'view',
  list: 'view',
  create: 'create',
  delete: 'delete',
  update: 'update',
  browse: 'browse',
  use: 'use',
  watch: 'view',
  adminTenant: 'admin-tenant'
}

// These wrapper classes are used to allow for the ability to specify certain attributes
// and should allow us to fine grain our permissions in the future
export class PulsarClusterWrapper {
  content: Cluster | { metadata: { name: string } }

  constructor(content: Cluster | { metadata: { name: string } }) {
    this.content = content
  }
}

export class PulsarInstanceWrapper {
  content: V1alpha1PulsarInstance | { metadata: { name: string } }

  constructor(content: V1alpha1PulsarInstance | { metadata: { name: string } }) {
    this.content = content
  }
}

export class TenantWrapper {
  name: string
  instanceName?: string
  clusterName?: string

  constructor(name: string, instanceName?: string, clusterName?: string) {
    this.name = name
    this.instanceName = instanceName
    this.clusterName = clusterName
  }

  matchesInstanceAndCluster(
    instanceName: string | undefined,
    clusterName: string | undefined
  ): boolean {
    return (
      (!instanceName || instanceName === this.instanceName) &&
      (!clusterName || clusterName === this.clusterName)
    )
  }
}

// see here https://casl.js.org/v5/en/advanced/customize-ability
// this allows us to pass a lambda to check if the conditions match

// If you touch this, you are required to write a unit test proving your changes work.
export type AppAbility = PureAbility<AbilityTuple, MatchConditions>
const lambdaMatcher = (matchConditions: MatchConditions) => matchConditions
const snAbility = new AbilityBuilder<AppAbility>(PureAbility).build({
  conditionsMatcher: lambdaMatcher
})
export const enabled = ref(false)
export const abilityUpdating = ref(false)
const tenantsWithAdministrationAbilities = ref<TenantWrapper[]>([])

async function buildOpenAbility(organization: string) {
  const { can, build } = new AbilityBuilder<AppAbility>(PureAbility)

  // Add all available actions for all available subjects
  subjects.forEach(subject => Object.values(verbActions).forEach(action => can(action, subject)))

  // Add can adminTenant for all wrapper types
  // TODO : Subclass AppAbility with a can function that always returns true or rule type that is always permissive
  const { instances } = useInstance()
  instances.value.forEach(instance =>
    can(adminTenant, 'PulsarInstanceWrapper', i => {
      const pulsarInstance = i.content as V1alpha1PulsarInstance
      return pulsarInstance.metadata?.name === instance
    })
  )

  const { getClusterMapRaw } = useCluster()
  const clusterMap = await getClusterMapRaw({ organization })

  Object.values(clusterMap).forEach(clusters => {
    clusters.forEach(clstr => {
      can(adminTenant, 'PulsarClusterWrapper', c => {
        const cluster = c.content as Cluster
        return cluster.metadata?.name === clstr.metadata?.name
      })
    })
  })

  // Add all tenants because if the user is not a super admin they cant
  // even request the tenants and if rbac is turned off then they also
  // cant request the tenants that they administer, so for the open ability
  // we turn ALL tenants into adminTenants
  can(adminTenant, 'TenantWrapper', t => {
    return true
  })

  // You would expect adminTenant permissions to be here for tenants, but since
  // it requires state to be set later in the initialization process to get those
  // the permissions are not going to be up to date. Since the user with an open
  // permission can 'admin' every instance they do not need these permissions and
  // `admin`, PulsarInstance should be sufficient.

  return build({
    conditionsMatcher: lambdaMatcher
  })
}

// this is set to a default to ensure that .rules is not undefined
export let openAbility: AppAbility = new AbilityBuilder<AppAbility>(PureAbility).build()

// TODO the `watch` function always creates a race condition
//      openAbility might not be defined.
// If enabled goes to false, default back to all everything goes
watch(enabled, newEnabled => {
  if (!newEnabled) {
    snAbility.update(openAbility.rules)
  }
})

const orgsRules: Record<string, SubjectRawRule<string, SubjectType, MatchConditions>[]> = {}
const staleOrgs = new Set<string>()

export const buildOrgRules = async (organization: string) => {
  const { can, build } = new AbilityBuilder<AppAbility>(PureAbility)
  const zeroAbilities = build()
  // first thing is removing existing abilities
  // then adding as appropriate later
  snAbility.update(zeroAbilities.rules)

  // auth is not ready
  if (!auth.isAuthenticated.value || !auth.user?.value?.email) {
    return
  }

  // Rebuild the open ability to have all permissions
  if (openAbility.rules.length === 0) {
    openAbility = await buildOpenAbility(organization)
  }

  if (!enabled.value) {
    snAbility.update(openAbility.rules)
    return
  }

  abilityUpdating.value = true

  if (staleOrgs.has(organization)) {
    staleOrgs.delete(organization)
    delete orgsRules[organization]
  }

  // Return a cached result for the organization
  if (orgsRules[organization]) {
    snAbility.update(orgsRules[organization])
    abilityUpdating.value = false
    return
  }

  // Calls to "can" don't dedupe, so we keep them in a mapping of subject -> actions
  // and call them once per action/subject
  const rules = Object.fromEntries(
    subjects.map(subject => [subject as Subject, new Set<string>()])
  ) as Record<Subject, Set<string>>

  const { clusterMap, getClusterMap } = useCluster()

  try {
    await selfSubjectRulesReviewRules(rules, organization)
    // Add all our rules to the ability
    forEach(rules, (value, subject) => {
      for (const action of value) {
        can(action, subject)
        if (subject === PulsarInstance) {
          can(action, Tenant)
        }
      }
    })

    try {
      tenantsWithAdministrationAbilities.value = []
      await getClusterMap({ organization: organization })

      for (const instance of Object.keys(clusterMap.value)) {
        for (const cluster of clusterMap.value[instance]) {
          if (await tenantsAdminApiIsAvailable(organization, cluster.metadata?.uid, instance)) {
            const data = await usePluginsApi(organization, cluster.metadata?.uid, instance)
              .getAdminTenants()
              .catch(e => {
                console.warn('useRbac getAdminTenants catch', e)
                throw e
              })

            const tenants = data.data

            for (const tenant of tenants) {
              can(adminTenant, PulsarInstance)
              can(adminTenant, PulsarCluster)

              can(adminTenant, 'PulsarInstanceWrapper', i => {
                const pulsarInstance = i.content as V1alpha1PulsarInstance
                return pulsarInstance.metadata?.name === instance
              })
              can(adminTenant, 'PulsarClusterWrapper', i => {
                const clstr = i.content as Cluster
                return clstr.metadata?.name === cluster.metadata?.name
              })

              tenantsWithAdministrationAbilities.value.push(
                new TenantWrapper(tenant, instance, cluster.metadata?.name)
              )

              can(adminTenant, 'TenantWrapper', i => {
                return (
                  i.name === tenant &&
                  i.instanceName === instance &&
                  i.clusterName === cluster.metadata?.name
                )
              })
            }
          } else {
            // TODO maybe we want to do a partial update here or even remove
            //      the ability if we can't get the tenants
            console.error(
              'Unable to get permissions information. The cluster might be down or not fully live yet.'
            )
          }
        }
      }
    } catch (e) {
      // TODO maybe we want to do a partial update here or even remove
      //      the ability if we can't get the tenants
      console.error(
        'Unable to get permissions information. The cluster might be down or not fully live yet.'
      )
      console.error(getErrorMessage(e))
    }
  } catch (e) {
    // TODO we might want to handle this better
    console.error(
      'Unable to get permissions information. The cluster might be down or not fully live yet.'
    )
    console.error(getErrorMessage(e))
  }

  const newOrgRules = build({
    conditionsMatcher: lambdaMatcher
  }).rules
  orgsRules[organization] = newOrgRules

  // Mark org to be cleared from the cache on the next call to build rules
  setTimeout(() => staleOrgs.add(organization), 300000)
  snAbility.update(newOrgRules)
  abilityUpdating.value = false
}

// Create a memoized version
const fetchSelfSubjectRulesReviewMemo = useMemoize(fetchSelfSubjectRulesReview)

async function selfSubjectRulesReviewRules(rules: Record<Subject, Set<string>>, namespace: string) {
  // If the rule has a resourceNames, this namespace must be included.
  const okForNamespace = (rule: V1alpha1ResourceRule) => {
    return (
      !rule.resourceNames ||
      (rule.resourceNames &&
        rule.resourceNames.length > 0 &&
        rule.resourceNames.includes(namespace))
    )
  }
  // prevent making fetchSelfSubjectRulesReview API call.  cloud-api-server changes are
  // not deployed to prod and we are not ready to make this API calls.  And we cannot
  // use LD flag here as it can be called before LDflag is initialized and due to
  // complicated vue's dependency issues
  // return rules

  const rulesReview = await fetchSelfSubjectRulesReviewMemo(namespace)

  // Map each subject to its verbs and Expand all "*"s to the full list of verbs for consistency
  // when checking the casl ability
  rulesReview.data?.status?.resourceRules?.filter(okForNamespace).forEach(rule => {
    rule.resources?.forEach(resource => {
      if (subjects.includes(resource as Subject)) {
        rule.verbs?.forEach(verb => {
          const subject = resource as Subject
          if (verb === '*' || verb === 'admin') {
            Object.values(verbActions).forEach(v => {
              rules[subject].add(v)
            })
          }

          if (verb in verbActions) {
            rules[subject].add(verbActions[verb])
          }
        })
      }
    })
  })
}

export const useRbac = () => {
  return {
    snAbility,
    enabled,
    abilityUpdating,
    tenantsWithAdministrationAbilities
  }
}
