import {
  useNonPersistentTopicPulsarAdmin,
  usePersistentTopicPulsarAdmin,
  useSchemaPulsarAdmin
} from '@/composables/pulsarAdmin'
import type {
  NonPersistentTopicStats,
  PostSchemaPayload,
  DispatchRateImpl,
  RetentionPolicies,
  SubscribeRate,
  TopicStats,
  BacklogQuotaImpl,
  PersistentTopicInternalStats,
  PartitionedTopicInternalStats
} from '@streamnative/pulsar-admin-client-typescript'

export const PARTITION_SEPARATOR = '-partition-'
export const PERSISTENT_PROTOCOL = 'persistent://'
export const NON_PERSISTENT_PROTOCOL = 'non-persistent://'

// used for when making api calls for multiple topics
export interface TopicApiParameters {
  organization: string
  clusterUid: string
  tenant: string
  namespace: string
}

// used for when making api calls for multiple topics
export interface TopicApiParametersForEndpoint extends TopicApiParameters {
  persistency: boolean
  partitioned: boolean
}
// used for when making api calls for a specific topic
export interface TopicActionParameters extends TopicApiParametersForEndpoint {
  topicName: string
}
export interface TopicMetric {
  // storage size
  storageSize?: number
  // backlog size
  backlogSize?: number
}
// getTopics API response, represents a topic in pulsar
export interface TopicDetails extends TopicActionParameters {
  topicRootName: string // i.e. `c1` and `c2`
  topicFullName: string // i.e. persistent://a/b/c2-partitioned-1

  /**
   * undefined if topic is not a partitioned.  i.e. undefined for `c1`
   * show partition id if defined. i.e. 1 for `c2-partitioned-1`
   */
  partitionId: string | undefined
  /**
   * 0 if topic it self is not partitioned. i.e. 0 for `c2-partitioned-0`
   * positive number if topic is partitoned. i.e. 2 for `c2`
   * undefined means we don't know yet
   */
  partitionCount: number | undefined

  metrics: TopicStats | NonPersistentTopicStats | undefined
  internalMetrics: PartitionedTopicInternalStats | PersistentTopicInternalStats | undefined
}

const buildTopicPulsarAdmin = ({
  organization,
  clusterUid,
  persistency
}: {
  organization: string
  clusterUid: string
  persistency: boolean
}) => {
  return persistency
    ? usePersistentTopicPulsarAdmin(organization, clusterUid)
    : useNonPersistentTopicPulsarAdmin(organization, clusterUid)
}
export type TopicMap = Record<string, TopicDetails> // short topic name to topic details

export const topicFullNameToDetail = (
  topicFullName: string,
  partitioned: boolean,
  partitionCount: number | undefined,
  params: {
    organization: string
    clusterUid: string
    tenant: string
    namespace: string
  },
  metrics?: TopicMetric
): TopicDetails => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [protocol, _, tenant, namespace, topicName] = topicFullName.split('/')
  const idx = topicName.lastIndexOf(PARTITION_SEPARATOR)
  const topicRootName = topicName.substring(0, idx < 0 ? topicName.length : idx)
  const partitionId = topicName.split(`${topicRootName}${PARTITION_SEPARATOR}`)[1] || undefined

  return {
    organization: params.organization,
    clusterUid: params.clusterUid,
    persistency: `${protocol}//` === PERSISTENT_PROTOCOL,
    partitioned,
    tenant,
    namespace,
    topicName,
    topicRootName,
    topicFullName,
    partitionId,
    partitionCount,
    metrics: metrics || { storageSize: undefined, backlogSize: undefined },
    internalMetrics: undefined
  }
}

export const fillPartitionedSubTopics = (topicDetail: TopicDetails, topicMap: TopicMap) => {
  for (let i = 0; i < (topicDetail.partitionCount || 0); i++) {
    const partitionedTopicFullname = `${topicDetail.topicFullName}${PARTITION_SEPARATOR}${i}`
    const partitionedTopicDetail = topicFullNameToDetail(
      partitionedTopicFullname,
      false,
      0,
      topicDetail
    )

    if (!topicMap[partitionedTopicDetail.topicName]) {
      topicMap[partitionedTopicDetail.topicName] = partitionedTopicDetail
    }
  }
}

/**
 * Given a list of topic names, fetch partition count if their partition count is undefined.
 * This will allow us lazily fetch partition counts if we don't know.
 *
 * This function will not refetch if we know of partition count despite function being called again
 * @param topicNames
 */
export const ensureTopicPartitionIsLoadedWithTopicDetails = async (
  topicDetails: TopicDetails[]
): Promise<TopicMap> => {
  const topicMapCopy: TopicMap = {}

  const promises = topicDetails.map(async topicDetail => {
    if (topicDetail) {
      if (topicDetail?.partitionCount === undefined) {
        const partitionMetaResp = await getPartitionedMetadata(topicDetail).catch(e => {
          console.warn('failed to fetch partition info for topic', topicDetail, e)
          return { data: { partitions: undefined } }
        })
        const partitionCount = partitionMetaResp.data.partitions
        if (partitionCount === undefined) {
          return
        }
        if (partitionCount < 0) {
          throw Error(`partition count of ${partitionCount} is invalid`)
        }
        topicDetail.partitioned = partitionCount > 0
        topicDetail.partitionCount = partitionCount
        topicMapCopy[topicDetail.topicName] = topicDetail

        fillPartitionedSubTopics(topicDetail, topicMapCopy)
      } else {
        topicMapCopy[topicDetail.topicName] = topicDetail
      }
    }
  })
  await Promise.all(promises)
  return topicMapCopy
}

const buildSchemaPulsarAdmin = ({
  organization,
  clusterUid
}: {
  organization: string
  clusterUid: string
}) => {
  return useSchemaPulsarAdmin(organization, clusterUid)
}

export const getTopicsAPIForEndpoint = (params: TopicApiParametersForEndpoint) => {
  const admin = buildTopicPulsarAdmin(params)
  if (params.partitioned) {
    return admin.getPartitionedTopicList(params.tenant, params.namespace, true)
  } else {
    // case: persistent === false
    // This one is odd, sometimes topics will include partitioned topics sometimes they don't
    // example payload with partition: ["non-persistent://a/b/c1", "no-persistent://a/b/c2-partition-0", "non-persistent://a/b/c2-partition-1"]
    // example payload without partition: ["non-persistent://a/b/c1"]
    return admin.getList(params.tenant, params.namespace, undefined, true)
  }
}

export const getAllTopics = async (params: {
  organization: string
  clusterUid: string
  tenant: string
  namespace: string
}): Promise<TopicMap> => {
  const topicResponses = await Promise.all([
    getTopicsAPIForEndpoint({ ...params, persistency: true, partitioned: true }),
    getTopicsAPIForEndpoint({ ...params, persistency: true, partitioned: false }),
    getTopicsAPIForEndpoint({ ...params, persistency: false, partitioned: true }),
    getTopicsAPIForEndpoint({ ...params, persistency: false, partitioned: false })
  ])
  const [persPart, persNoPart, noPersNoPart, noPersPart] = topicResponses.map((res, index) => {
    return Object.fromEntries(
      res.data.map(name => {
        let partitionCount = undefined
        const partitioned = !/-partition-\d+$/.test(name)
        if (index === 0) {
          partitionCount =
            topicResponses[1].data.filter(_name =>
              new RegExp(`${name}${PARTITION_SEPARATOR}d+$`).test(_name)
            ).length || undefined
        }
        if (index === 2) {
          partitionCount =
            topicResponses[3].data.filter(_name =>
              new RegExp(`${name}${PARTITION_SEPARATOR}`).test(_name)
            ).length || undefined
        }
        const topicDetail = topicFullNameToDetail(name, partitioned, partitionCount, params)
        return [topicDetail.topicName, topicDetail]
      })
    )
  })

  return {
    ...persPart,
    ...persNoPart,
    ...noPersPart,
    ...noPersNoPart
  }
}

export const createTopic = (params: TopicActionParameters & { partitionCount: number }) => {
  const admin = buildTopicPulsarAdmin(params)
  if (params.partitioned) {
    return admin.createPartitionedTopic(
      params.tenant,
      params.namespace,
      params.topicName,
      params.partitionCount
    )
  } else {
    return admin.createNonPartitionedTopic(params.tenant, params.namespace, params.topicName)
  }
}

export const updateTopic = (params: TopicActionParameters & { partitionCount: number }) => {
  const admin = buildTopicPulsarAdmin(params)
  if (params.partitioned) {
    return admin.updatePartitionedTopic(
      params.tenant,
      params.namespace,
      params.topicName,
      params.partitionCount
    )
  }
}

export const deleteTopic = (params: TopicActionParameters) => {
  const admin = buildTopicPulsarAdmin(params)
  if (params.partitioned) {
    return admin.deletePartitionedTopic(params.tenant, params.namespace, params.topicName)
  } else {
    return admin.deleteTopic(params.tenant, params.namespace, params.topicName)
  }
}

export const fetchTopicStats = (params: TopicActionParameters) => {
  const admin = buildTopicPulsarAdmin(params)
  if (params.partitioned) {
    return admin.getPartitionedStats(params.tenant, params.namespace, params.topicName, true)
  } else {
    return admin.getStats(params.tenant, params.namespace, params.topicName)
  }
}

export const createSubscription = (
  params: TopicActionParameters & { subscriptionName: string }
) => {
  const admin = buildTopicPulsarAdmin(params)
  return admin.createSubscription(
    params.tenant,
    params.namespace,
    params.topicName,
    params.subscriptionName
  )
}

export const deleteSubscription = (
  params: TopicActionParameters & { subscriptionName: string }
) => {
  const admin = buildTopicPulsarAdmin(params)
  return admin.deleteSubscription(
    params.tenant,
    params.namespace,
    params.topicName,
    params.subscriptionName
  )
}

export const resetCursor = (
  params: TopicActionParameters & { subscriptionName: string; timestamp: number }
) => {
  const admin = buildTopicPulsarAdmin(params)
  return admin.resetCursor(
    params.tenant,
    params.namespace,
    params.topicName,
    params.subscriptionName,
    params.timestamp
  )
}

export const skipAllMessages = (params: TopicActionParameters & { subscriptionName: string }) => {
  const admin = buildTopicPulsarAdmin(params)
  return admin.skipAllMessages(
    params.tenant,
    params.namespace,
    params.topicName,
    params.subscriptionName
  )
}

export const getTopicBundle = (params: TopicActionParameters) => {
  const admin = buildTopicPulsarAdmin(params)
  return admin.getTopicBundle(params.tenant, params.namespace, params.topicName)
}

export const getTopicBroker = (params: TopicActionParameters) => {
  const admin = buildTopicPulsarAdmin(params)
  return admin.getTopicBroker(params.tenant, params.namespace, params.topicName)
}

export const fetchAllSchemas = (params: TopicActionParameters) => {
  const admin = buildSchemaPulsarAdmin(params)
  return admin.getAll(params.tenant, params.namespace, params.topicName)
}

export const deleteSchema = (params: TopicActionParameters) => {
  const admin = buildSchemaPulsarAdmin(params)
  return admin.deleteSchema(params.tenant, params.namespace, params.topicName)
}

export const postSchema = (params: TopicActionParameters & { body: PostSchemaPayload }) => {
  const admin = buildSchemaPulsarAdmin(params)
  return admin.postSchema(params.tenant, params.namespace, params.topicName, undefined, {
    type: params.body.type,
    schema: params.body.schema,
    properties: params.body.properties
  })
}

export const getInternalStats = (params: TopicActionParameters) => {
  const admin = buildTopicPulsarAdmin(params)
  if (params.partitioned) {
    return admin.getPartitionedStatsInternal(params.tenant, params.namespace, params.topicName)
  }
  return admin.getInternalStats(params.tenant, params.namespace, params.topicName)
}

export const unloadTopic = (params: TopicActionParameters) => {
  const admin = buildTopicPulsarAdmin(params)
  return admin.unloadTopic(params.tenant, params.namespace, params.topicName)
}

export const compactionStatus = (params: TopicActionParameters) => {
  const admin = buildTopicPulsarAdmin(params)
  return admin.compactionStatus(params.tenant, params.namespace, params.topicName)
}

export const compact = (params: TopicActionParameters) => {
  const admin = buildTopicPulsarAdmin(params)
  return admin.compact(params.tenant, params.namespace, params.topicName)
}

export const getPermissionsOnTopic = (params: TopicActionParameters) => {
  const admin = buildTopicPulsarAdmin(params)
  return admin.getPermissionsOnTopic(params.tenant, params.namespace, params.topicName)
}

export const grantPermissionsOnTopic = (
  params: TopicActionParameters & { role: string; permissions: string[] }
) => {
  const admin = buildTopicPulsarAdmin(params)
  return admin.grantPermissionsOnTopic(
    params.tenant,
    params.namespace,
    params.topicName,
    params.role,
    params.permissions
  )
}

export const revokePermissionsOnTopic = (params: TopicActionParameters & { role: string }) => {
  const admin = buildTopicPulsarAdmin(params)
  return admin.revokePermissionsOnTopic(
    params.tenant,
    params.namespace,
    params.topicName,
    params.role
  )
}

export const getPartitionedMetadata = (params: TopicActionParameters) => {
  const admin = buildTopicPulsarAdmin(params)
  return admin.getPartitionedMetadata(params.tenant, params.namespace, params.topicName)
}

export const peekNthMessage = async (
  params: TopicActionParameters & {
    subscriptionName: string
    n: number
  }
) => {
  const admin = buildTopicPulsarAdmin(params)

  // returns binrary data
  return await admin.peekNthMessage(
    params.tenant,
    params.namespace,
    params.topicName,
    params.subscriptionName,
    params.n,
    undefined,
    { responseType: 'arraybuffer' }
  )
}

export const getTopicStats = async (
  topicDetail: TopicDetails
): Promise<TopicStats | NonPersistentTopicStats> => {
  await ensureTopicPartitionIsLoadedWithTopicDetails([topicDetail])
  return (await fetchTopicStats(topicDetail)).data
}

export const getBacklogQuota = (params: TopicActionParameters) => {
  const admin = buildTopicPulsarAdmin(params)
  return admin.getBacklogQuotaMap(params.tenant, params.namespace, params.topicName)
}

export const setBacklogQuota = (
  params: TopicActionParameters & {
    backlogQuotaType: 'destination_storage' | 'message_age'
    backlogQuota: BacklogQuotaImpl
  }
) => {
  const admin = buildTopicPulsarAdmin(params)
  return admin.setBacklogQuota(
    params.tenant,
    params.namespace,
    params.topicName,
    undefined,
    undefined,
    params.backlogQuotaType,
    params.backlogQuota
  )
}

export const getDispatchRate = (params: TopicActionParameters) => {
  const admin = buildTopicPulsarAdmin(params)
  return admin.getDispatchRate(params.tenant, params.namespace, params.topicName)
}

export const setDispatchRate = (
  params: TopicActionParameters & { dispatchRate?: DispatchRateImpl }
) => {
  const admin = buildTopicPulsarAdmin(params)
  return admin.setDispatchRate(
    params.tenant,
    params.namespace,
    params.topicName,
    undefined,
    undefined,
    params.dispatchRate
  )
}

export const getSubscriptionDispatchRate = (params: TopicActionParameters) => {
  const admin = buildTopicPulsarAdmin(params)
  return admin.getSubscriptionDispatchRate(params.tenant, params.namespace, params.topicName)
}

export const setSubscriptionDispatchRate = (
  params: TopicActionParameters & { subscriptionDispatchRate?: DispatchRateImpl }
) => {
  const admin = buildTopicPulsarAdmin(params)
  return admin.setSubscriptionDispatchRate(
    params.tenant,
    params.namespace,
    params.topicName,
    undefined,
    undefined,
    params.subscriptionDispatchRate
  )
}

export const getSubscribeRate = (params: TopicActionParameters) => {
  const admin = buildTopicPulsarAdmin(params)
  return admin.getSubscribeRate(params.tenant, params.namespace, params.topicName)
}

export const setSubscribeRate = (
  params: TopicActionParameters & { subscribeRate?: SubscribeRate }
) => {
  const admin = buildTopicPulsarAdmin(params)
  return admin.setSubscribeRate(
    params.tenant,
    params.namespace,
    params.topicName,
    undefined,
    undefined,
    params.subscribeRate
  )
}

export const getMessageTTL = (params: TopicActionParameters) => {
  const admin = buildTopicPulsarAdmin(params)
  return admin.getMessageTTL(params.tenant, params.namespace, params.topicName)
}

export const setMessageTTL = (params: TopicActionParameters & { messageTTL: number }) => {
  const admin = buildTopicPulsarAdmin(params)
  return admin.setMessageTTL(params.tenant, params.namespace, params.topicName, params.messageTTL)
}

export const getRetention = (params: TopicActionParameters) => {
  const admin = buildTopicPulsarAdmin(params)
  return admin.getRetention(params.tenant, params.namespace, params.topicName)
}

export const setRetention = (
  params: TopicActionParameters & { retentionPolicies?: RetentionPolicies }
) => {
  const admin = buildTopicPulsarAdmin(params)
  return admin.setRetention(
    params.tenant,
    params.namespace,
    params.topicName,
    undefined,
    undefined,
    params.retentionPolicies
  )
}

export const getCompactionThreshold = (params: TopicActionParameters) => {
  const admin = buildTopicPulsarAdmin(params)
  return admin.getCompactionThreshold(params.tenant, params.namespace, params.topicName)
}

export const setCompactionThreshold = (
  params: TopicActionParameters & { compactionThreshold?: number }
) => {
  const admin = buildTopicPulsarAdmin(params)
  return admin.setCompactionThreshold(
    params.tenant,
    params.namespace,
    params.topicName,
    undefined,
    undefined,
    params.compactionThreshold
  )
}

export const getMaxConsumers = (params: TopicActionParameters) => {
  const admin = buildTopicPulsarAdmin(params)
  return admin.getMaxConsumers(params.tenant, params.namespace, params.topicName)
}

export const setMaxConsumers = (params: TopicActionParameters & { maxConsumers?: number }) => {
  const admin = buildTopicPulsarAdmin(params)
  return admin.setMaxConsumers(
    params.tenant,
    params.namespace,
    params.topicName,
    undefined,
    undefined,
    params.maxConsumers
  )
}

export const getMaxConsumersPerSubscription = (params: TopicActionParameters) => {
  const admin = buildTopicPulsarAdmin(params)
  return admin.getMaxConsumersPerSubscription(params.tenant, params.namespace, params.topicName)
}

export const setMaxConsumersPerSubscription = (
  params: TopicActionParameters & { maxConsumersPerSubscription?: number }
) => {
  const admin = buildTopicPulsarAdmin(params)
  return admin.setMaxConsumersPerSubscription(
    params.tenant,
    params.namespace,
    params.topicName,
    undefined,
    undefined,
    params.maxConsumersPerSubscription
  )
}

export const getMaxProducers = (params: TopicActionParameters) => {
  const admin = buildTopicPulsarAdmin(params)
  return admin.getMaxProducers(params.tenant, params.namespace, params.topicName)
}

export const setMaxProducers = (params: TopicActionParameters & { maxProducers?: number }) => {
  const admin = buildTopicPulsarAdmin(params)
  return admin.setMaxProducers(
    params.tenant,
    params.namespace,
    params.topicName,
    undefined,
    undefined,
    params.maxProducers
  )
}
