import type {
  V1alpha1Role,
  V1alpha1ClusterRole,
  V1alpha1RoleBinding
} from '@streamnative/cloud-api-client-typescript'
import { useCloudApi } from '@/composables/cloudApi'
import {
  type Authorizer,
  NewAuthorizerWithPrivilegesString
} from '@streamnative/unified-rbac/src/sdk'
import { fetchSelfSubjectRbacReview } from '@/api/rbacV2'
import { useMemoize } from '@vueuse/core'
import { auth } from '@/auth'
import type { SRN } from '@streamnative/unified-rbac/src/proto/condition'

export const rbacV2Enabled = ref(false)
export const rbacV2Updating = ref(false)

const permissions = [
  'cloud.selfOrganizations.alter',
  'billing.customerportalrequests.alter',
  'billing.customerportalrequests.create',
  'billing.customerportalrequests.delete',
  'billing.customerportalrequests.describe',
  'billing.paymentintents.describe',
  'billing.paymentintents.create',
  'billing.paymentintents.delete',
  'billing.paymentintents.alter',
  'billing.products.describe',
  'billing.products.create',
  'billing.products.delete',
  'billing.products.alter',
  'billing.setupintents.describe',
  'billing.setupintents.create',
  'billing.setupintents.delete',
  'billing.setupintents.alter',
  'billing.subscriptionintents.alter',
  'billing.subscriptionintents.create',
  'billing.subscriptionintents.delete',
  'billing.subscriptionintents.describe',
  'billing.subscriptions.describe',
  'cloud.apikeys.alter',
  'cloud.apikeys.create',
  'cloud.apikeys.delete',
  'cloud.apikeys.describe',
  'cloud.bookkeepersetoptions.describe',
  'cloud.cloudconnections.alter',
  'cloud.cloudconnections.create',
  'cloud.cloudconnections.delete',
  'cloud.cloudconnections.describe',
  'cloud.cloudenvironments.alter',
  'cloud.cloudenvironments.create',
  'cloud.cloudenvironments.delete',
  'cloud.cloudenvironments.describe',
  'cloud.unilink.alter',
  'cloud.unilink.create',
  'cloud.unilink.delete',
  'cloud.unilink.describe',
  'cloud.identitypools.alter',
  'cloud.identitypools.create',
  'cloud.identitypools.delete',
  'cloud.identitypools.describe',
  'cloud.oidcproviders.alter',
  'cloud.oidcproviders.create',
  'cloud.oidcproviders.delete',
  'cloud.oidcproviders.describe',
  'cloud.poolmembers.describe',
  'cloud.pooloptions.describe',
  'cloud.pools.describe',
  'cloud.pulsarclusters.alter',
  'cloud.pulsarclusters.create',
  'cloud.pulsarclusters.delete',
  'cloud.pulsarclusters.describe',
  'cloud.pulsargateways.alter',
  'cloud.pulsargateways.create',
  'cloud.pulsargateways.delete',
  'cloud.pulsargateways.describe',
  'cloud.pulsarinstances.alter',
  'cloud.pulsarinstances.create',
  'cloud.pulsarinstances.delete',
  'cloud.pulsarinstances.describe',
  'cloud.rolebindings.alter',
  'cloud.rolebindings.create',
  'cloud.rolebindings.delete',
  'cloud.rolebindings.describe',
  'cloud.roles.alter',
  'cloud.roles.create',
  'cloud.roles.delete',
  'cloud.roles.describe',
  'cloud.secrets.alter',
  'cloud.secrets.create',
  'cloud.secrets.delete',
  'cloud.secrets.describe',
  'cloud.serviceaccountbindings.alter',
  'cloud.serviceaccountbindings.create',
  'cloud.serviceaccountbindings.delete',
  'cloud.serviceaccountbindings.describe',
  'cloud.serviceaccounts.alter',
  'cloud.serviceaccounts.create',
  'cloud.serviceaccounts.delete',
  'cloud.serviceaccounts.describe',
  'cloud.users.alter',
  'cloud.users.create',
  'cloud.users.delete',
  'cloud.users.describe',
  'cloud.volumes.alter',
  'cloud.volumes.create',
  'cloud.volumes.delete',
  'cloud.volumes.describe',
  'cloud.catalogs.alter',
  'cloud.catalogs.create',
  'cloud.catalogs.delete',
  'cloud.catalogs.describe',
  'cloud.zookeepersetoptions.describe',
  'pulsar.all.admin',
  'pulsar.tenants.describe',
  'pulsar.tenants.alter',
  'pulsar.tenants.create',
  'pulsar.tenants.delete',
  'pulsar.namespaces.describe',
  'pulsar.namespaces.alter',
  'pulsar.namespaces.create',
  'pulsar.namespaces.delete',
  'pulsar.topics.describe',
  'pulsar.topics.alter',
  'pulsar.topics.create',
  'pulsar.topics.delete',
  'pulsar.subscriptions.describe',
  'pulsar.subscriptions.alter',
  'pulsar.subscriptions.create',
  'pulsar.subscriptions.delete',
  'pulsar.messages.produce',
  'pulsar.messages.consume',
  'pulsar.policies.describe',
  'pulsar.policies.alter',
  'pulsar.function.describe',
  'pulsar.function.alter',
  'pulsar.function.create',
  'pulsar.function.delete',
  'pulsar.sink.describe',
  'pulsar.sink.alter',
  'pulsar.sink.create',
  'pulsar.sink.delete',
  'pulsar.source.describe',
  'pulsar.source.alter',
  'pulsar.source.create',
  'pulsar.source.delete',
  'pulsar.clusters.describe',
  'pulsar.clusters.alter',
  'pulsar.clusters.create',
  'pulsar.clusters.delete',
  'pulsar.brokers.describe',
  'pulsar.brokers.alter',
  'pulsar.brokers.create',
  'pulsar.brokers.delete',
  'ksn.sources.describe',
  'ksn.sources.alter',
  'ksn.sources.create',
  'ksn.sources.delete',
  'ksn.sinks.describe',
  'ksn.sinks.alter',
  'ksn.sinks.create',
  'ksn.sinks.delete',
  'cloudMetrics.pulsar.query',
  'cloudMetrics.pulsar.export',
  'cloudMetrics.function.export',
  'cloudMetrics.source.export',
  'cloudMetrics.sink.export',
  'cloudMetrics.kafkaConnect.export',
  'cloudMetrics.health.export',
  'cloudMetrics.billing.query',
  'cloudMetrics.subscriptionStripe.query',
  'cloudMetrics.usageStrip.query',
  'cloudMetrics.consumptionStripe.query',
  'cloudMetrics.consumptionSuger.query',
  'cloudMetrics.costStripe.query',
  'cloudMetrics.costSuger.query',
  'cloudMetrics.termSuger.query'
] as const

export type Permission = (typeof permissions)[number]

export const CloudSelfOrganizationsAlter: Permission = 'cloud.selfOrganizations.alter'
export const BillingCustomerportalrequestsAlter: Permission = 'billing.customerportalrequests.alter'
export const BillingCustomerportalrequestsCreate: Permission =
  'billing.customerportalrequests.create'
export const BillingCustomerportalrequestsDelete: Permission =
  'billing.customerportalrequests.delete'
export const BillingCustomerportalrequestsDescribe: Permission =
  'billing.customerportalrequests.describe'
export const BillingPaymentintentsDescribe: Permission = 'billing.paymentintents.describe'
export const BillingPaymentintentsCreate: Permission = 'billing.paymentintents.create'
export const BillingPaymentintentsDelete: Permission = 'billing.paymentintents.delete'
export const BillingPaymentintentsAlter: Permission = 'billing.paymentintents.alter'
export const BillingProductsDescribe: Permission = 'billing.products.describe'
export const BillingProductsCreate: Permission = 'billing.products.create'
export const BillingProductsDelete: Permission = 'billing.products.delete'
export const BillingProductsAlter: Permission = 'billing.products.alter'
export const BillingSetupintentsDescribe: Permission = 'billing.setupintents.describe'
export const BillingSetupintentsCreate: Permission = 'billing.setupintents.create'
export const BillingSetupintentsDelete: Permission = 'billing.setupintents.delete'
export const BillingSetupintentsAlter: Permission = 'billing.setupintents.alter'
export const BillingSubscriptionintentsAlter: Permission = 'billing.subscriptionintents.alter'
export const BillingSubscriptionintentsCreate: Permission = 'billing.subscriptionintents.create'
export const BillingSubscriptionintentsDelete: Permission = 'billing.subscriptionintents.delete'
export const BillingSubscriptionsDescribe: Permission = 'billing.subscriptions.describe'
export const CloudApikeysAlter: Permission = 'cloud.apikeys.alter'
export const CloudApikeysCreate: Permission = 'cloud.apikeys.create'
export const CloudApikeysDelete: Permission = 'cloud.apikeys.delete'
export const CloudApikeysDescribe: Permission = 'cloud.apikeys.describe'
export const CloudBookkeepersetoptionsDescribe: Permission = 'cloud.bookkeepersetoptions.describe'
export const CloudCloudconnectionsAlter: Permission = 'cloud.cloudconnections.alter'
export const CloudCloudconnectionsCreate: Permission = 'cloud.cloudconnections.create'
export const CloudCloudconnectionsDelete: Permission = 'cloud.cloudconnections.delete'
export const CloudCloudconnectionsDescribe: Permission = 'cloud.cloudconnections.describe'
export const CloudCloudenvironmentsAlter: Permission = 'cloud.cloudenvironments.alter'
export const CloudCloudenvironmentsCreate: Permission = 'cloud.cloudenvironments.create'
export const CloudCloudenvironmentsDelete: Permission = 'cloud.cloudenvironments.delete'
export const CloudCloudenvironmentsDescribe: Permission = 'cloud.cloudenvironments.describe'
export const CloudIdentitypoolsAlter: Permission = 'cloud.identitypools.alter'
export const CloudIdentitypoolsCreate: Permission = 'cloud.identitypools.create'
export const CloudIdentitypoolsDelete: Permission = 'cloud.identitypools.delete'
export const CloudIdentitypoolsDescribe: Permission = 'cloud.identitypools.describe'
export const CloudOidcprovidersAlter: Permission = 'cloud.oidcproviders.alter'
export const CloudOidcprovidersCreate: Permission = 'cloud.oidcproviders.create'
export const CloudOidcprovidersDelete: Permission = 'cloud.oidcproviders.delete'
export const CloudOidcprovidersDescribe: Permission = 'cloud.oidcproviders.describe'
export const CloudPoolmembersDescribe: Permission = 'cloud.poolmembers.describe'
export const CloudPooloptionsDescribe: Permission = 'cloud.pooloptions.describe'
export const CloudPoolsDescribe: Permission = 'cloud.pools.describe'
export const CloudPulsarclustersAlter: Permission = 'cloud.pulsarclusters.alter'
export const CloudPulsarclustersCreate: Permission = 'cloud.pulsarclusters.create'
export const CloudPulsarclustersDelete: Permission = 'cloud.pulsarclusters.delete'
export const CloudPulsarclustersDescribe: Permission = 'cloud.pulsarclusters.describe'
export const CloudPulsargatewaysAlter: Permission = 'cloud.pulsargateways.alter'
export const CloudPulsargatewaysCreate: Permission = 'cloud.pulsargateways.create'
export const CloudPulsargatewaysDelete: Permission = 'cloud.pulsargateways.delete'
export const CloudPulsargatewaysDescribe: Permission = 'cloud.pulsargateways.describe'
export const CloudPulsarinstancesAlter: Permission = 'cloud.pulsarinstances.alter'
export const CloudPulsarinstancesCreate: Permission = 'cloud.pulsarinstances.create'
export const CloudPulsarinstancesDelete: Permission = 'cloud.pulsarinstances.delete'
export const CloudPulsarinstancesDescribe: Permission = 'cloud.pulsarinstances.describe'
export const CloudRolebindingsAlter: Permission = 'cloud.rolebindings.alter'
export const CloudRolebindingsCreate: Permission = 'cloud.rolebindings.create'
export const CloudRolebindingsDelete: Permission = 'cloud.rolebindings.delete'
export const CloudRolebindingsDescribe: Permission = 'cloud.rolebindings.describe'
export const CloudRolesAlter: Permission = 'cloud.roles.alter'
export const CloudRolesCreate: Permission = 'cloud.roles.create'
export const CloudRolesDelete: Permission = 'cloud.roles.delete'
export const CloudRolesDescribe: Permission = 'cloud.roles.describe'
export const CloudSecretsAlter: Permission = 'cloud.secrets.alter'
export const CloudSecretsCreate: Permission = 'cloud.secrets.create'
export const CloudSecretsDelete: Permission = 'cloud.secrets.delete'
export const CloudSecretsDescribe: Permission = 'cloud.secrets.describe'
export const CloudServiceaccountbindingsAlter: Permission = 'cloud.serviceaccountbindings.alter'
export const CloudServiceaccountbindingsCreate: Permission = 'cloud.serviceaccountbindings.create'
export const CloudServiceaccountbindingsDelete: Permission = 'cloud.serviceaccountbindings.delete'
export const CloudServiceaccountbindingsDescribe: Permission =
  'cloud.serviceaccountbindings.describe'
export const CloudServiceaccountsAlter: Permission = 'cloud.serviceaccounts.alter'
export const CloudServiceaccountsCreate: Permission = 'cloud.serviceaccounts.create'
export const CloudServiceaccountsDelete: Permission = 'cloud.serviceaccounts.delete'
export const CloudServiceaccountsDescribe: Permission = 'cloud.serviceaccounts.describe'
export const CloudUsersAlter: Permission = 'cloud.users.alter'
export const CloudUsersCreate: Permission = 'cloud.users.create'
export const CloudUsersDelete: Permission = 'cloud.users.delete'
export const CloudUsersDescribe: Permission = 'cloud.users.describe'
export const CloudZookeepersetoptionsDescribe: Permission = 'cloud.zookeepersetoptions.describe'
export const PulsarAllAdmin: Permission = 'pulsar.all.admin'
export const PulsarTenantsDescribe: Permission = 'pulsar.tenants.describe'
export const PulsarTenantsAlter: Permission = 'pulsar.tenants.alter'
export const PulsarTenantsCreate: Permission = 'pulsar.tenants.create'
export const PulsarTenantsDelete: Permission = 'pulsar.tenants.delete'
export const PulsarNamespacesDescribe: Permission = 'pulsar.namespaces.describe'
export const PulsarNamespacesAlter: Permission = 'pulsar.namespaces.alter'
export const PulsarNamespacesCreate: Permission = 'pulsar.namespaces.create'
export const PulsarNamespacesDelete: Permission = 'pulsar.namespaces.delete'
export const PulsarTopicsDescribe: Permission = 'pulsar.topics.describe'
export const PulsarTopicsAlter: Permission = 'pulsar.topics.alter'
export const PulsarTopicsCreate: Permission = 'pulsar.topics.create'
export const PulsarTopicsDelete: Permission = 'pulsar.topics.delete'
export const PulsarSubscriptionsDescribe: Permission = 'pulsar.subscriptions.describe'
export const PulsarSubscriptionsAlter: Permission = 'pulsar.subscriptions.alter'
export const PulsarSubscriptionsCreate: Permission = 'pulsar.subscriptions.create'
export const PulsarSubscriptionsDelete: Permission = 'pulsar.subscriptions.delete'
export const PulsarMessagesProduce: Permission = 'pulsar.messages.produce'
export const PulsarMessagesConsume: Permission = 'pulsar.messages.consume'
export const PulsarPoliciesDescribe: Permission = 'pulsar.policies.describe'
export const PulsarPoliciesAlter: Permission = 'pulsar.policies.alter'
export const PulsarFunctionsDescribe: Permission = 'pulsar.function.describe'
export const PulsarFunctionsAlter: Permission = 'pulsar.function.alter'
export const PulsarFunctionsCreate: Permission = 'pulsar.function.create'
export const PulsarFunctionsDelete: Permission = 'pulsar.function.delete'
export const PulsarSinksDescribe: Permission = 'pulsar.sink.describe'
export const PulsarSinksAlter: Permission = 'pulsar.sink.alter'
export const PulsarSinksCreate: Permission = 'pulsar.sink.create'
export const PulsarSinksDelete: Permission = 'pulsar.sink.delete'
export const PulsarSourcesDescribe: Permission = 'pulsar.source.describe'
export const PulsarSourcesAlter: Permission = 'pulsar.source.alter'
export const PulsarSourcesCreate: Permission = 'pulsar.source.create'
export const PulsarSourcesDelete: Permission = 'pulsar.source.delete'
export const PulsarClustersDescribe: Permission = 'pulsar.clusters.describe'
export const PulsarClustersAlter: Permission = 'pulsar.clusters.alter'
export const PulsarClustersCreate: Permission = 'pulsar.clusters.create'
export const PulsarClustersDelete: Permission = 'pulsar.clusters.delete'
export const PulsarBrokersDescribe: Permission = 'pulsar.brokers.describe'
export const PulsarBrokersAlter: Permission = 'pulsar.brokers.alter'
export const PulsarBrokersCreate: Permission = 'pulsar.brokers.create'
export const PulsarBrokersDelete: Permission = 'pulsar.brokers.delete'
export const KsnSourcesDescribe: Permission = 'ksn.sources.describe'
export const KsnSourcesAlter: Permission = 'ksn.sources.alter'
export const KsnSourcesCreate: Permission = 'ksn.sources.create'
export const KsnSourcesDelete: Permission = 'ksn.sources.delete'
export const KsnSinksDescribe: Permission = 'ksn.sinks.describe'
export const KsnSinksAlter: Permission = 'ksn.sinks.alter'
export const KsnSinksCreate: Permission = 'ksn.sinks.create'
export const KsnSinksDelete: Permission = 'ksn.sinks.delete'
export const CloudMetricsPulsarQuery: Permission = 'cloudMetrics.pulsar.query'
export const CloudMetricsPulsarExport: Permission = 'cloudMetrics.pulsar.export'
export const CloudMetricsFunctionExport: Permission = 'cloudMetrics.function.export'
export const CloudMetricsSourceExport: Permission = 'cloudMetrics.source.export'
export const CloudMetricsSinkExport: Permission = 'cloudMetrics.sink.export'
export const CloudMetricsKafkaConnectExport: Permission = 'cloudMetrics.kafkaConnect.export'
export const CloudMetricsHealthExport: Permission = 'cloudMetrics.health.export'
export const CloudMetricsBillingQuery: Permission = 'cloudMetrics.billing.query'
export const CloudMetricsSubscriptionStripeQuery: Permission =
  'cloudMetrics.subscriptionStripe.query'
export const CloudMetricsUsageStripQuery: Permission = 'cloudMetrics.usageStrip.query'
export const CloudMetricsConsumptionStripeQuery: Permission = 'cloudMetrics.consumptionStripe.query'
export const CloudMetricsConsumptionSugerQuery: Permission = 'cloudMetrics.consumptionSuger.query'
export const CloudMetricsCostStripeQuery: Permission = 'cloudMetrics.costStripe.query'
export const CloudMetricsCostSugerQuery: Permission = 'cloudMetrics.costSuger.query'
export const CloudMetricsTermSugerQuery: Permission = 'cloudMetrics.termSuger.query'
export const CloudUnilinkAlter: Permission = 'cloud.unilink.alter'
export const CloudUnilinkCreate: Permission = 'cloud.unilink.create'
export const CloudUnilinkDelete: Permission = 'cloud.unilink.delete'
export const CloudUnilinkDescribe: Permission = 'cloud.unilink.describe'
export const CloudVolumesAlter: Permission = 'cloud.volumes.alter'
export const CloudVolumesCreate: Permission = 'cloud.volumes.create'
export const CloudVolumesDelete: Permission = 'cloud.volumes.delete'
export const CloudVolumesDescribe: Permission = 'cloud.volumes.describe'
export const CloudCatalogsAlter: Permission = 'cloud.catalogs.alter'
export const CloudCatalogsCreate: Permission = 'cloud.catalogs.create'
export const CloudCatalogsDelete: Permission = 'cloud.catalogs.delete'
export const CloudCatalogsDescribe: Permission = 'cloud.catalogs.describe'

const userPrivileges = ref<string>()
const authorizer = ref<Authorizer>()
const fetchSelfSubjectRbacReviewMemo = useMemoize(fetchSelfSubjectRbacReview)

export const buildOrgRbac = async (organization: string) => {
  if (!rbacV2Enabled.value) {
    return
  }
  try {
    rbacV2Updating.value = true
    const { data: rbacReview } = await fetchSelfSubjectRbacReviewMemo(organization)
    userPrivileges.value = rbacReview?.status?.userPrivileges
    authorizer.value = NewAuthorizerWithPrivilegesString(userPrivileges.value ?? '')
  } catch (error) {
    console.error('Failed to update rbac', error)
  } finally {
    rbacV2Updating.value = false
  }
}

export const authorize = (permission: Permission | { permission: Permission; srn?: SRN }) => {
  if (!rbacV2Enabled.value) {
    console.log('RbacV2 disabled, returning true')
    return true
  }
  const { user } = auth
  if (!authorizer.value || !user.value?.email) {
    console.error('No authorizer or user email')
    return false
  }
  const _authorizer = authorizer.value
  const _email = user.value.email
  const _permission: { permission: Permission; srn?: SRN } =
    typeof permission === 'string' ? { permission: permission, srn: undefined } : permission
  const { allowed, error } = _authorizer.authorize(_email, _permission.permission, {
    organization: _permission.srn?.organization || '',
    instance: _permission.srn?.instance || '',
    cluster: _permission.srn?.cluster || '',
    tenant: _permission.srn?.tenant || '',
    namespace: _permission.srn?.namespace || '',
    topic_domain: _permission.srn?.topic_domain || '',
    topic_name: _permission.srn?.topic_name || '',
    subscription: _permission.srn?.subscription || ''
  } as SRN)
  if (error) {
    console.error(error)
  }
  // console.log(`RbacV2 authorized <${allowed}> for`, JSON.stringify(permission, null, 2))
  return allowed
}

const namespacedRoles = ref<V1alpha1Role[]>([])
const clusterRoles = ref<V1alpha1ClusterRole[]>([])
const roleBindings = ref<V1alpha1RoleBinding[]>([])

const getRoles = async (organization: string) => {
  const api = useCloudApi()
  const {
    data: { items: _clusterRoles }
  } = await api.listClusterRole()
  const {
    data: { items: _namespacedRoles }
  } = await api.listNamespacedRole(organization)
  namespacedRoles.value = _namespacedRoles
  clusterRoles.value = _clusterRoles
  return { namespacedRoles: namespacedRoles.value, clusterRoles: clusterRoles.value }
}

const getRoleBindings = async (organization: string) => {
  const api = useCloudApi()
  const {
    data: { items: _roleBindings }
  } = await api.listNamespacedRoleBinding(organization)
  roleBindings.value = _roleBindings
  return roleBindings.value
}

const roles = computed(() => [...clusterRoles.value, ...namespacedRoles.value])

const rolesOfUser = async (user: string) => {
  const roleNames = roleBindings.value
    .filter(rb =>
      rb.spec?.subjects?.some(subject => subject.name === user && subject.kind === 'User')
    )
    .map(rb => rb.spec?.roleRef.name)
  return roles.value.filter(role => roleNames.includes(role.metadata?.name ?? ''))
}

const rolesOfServiceAccount = async (serviceAccount: string) => {
  const roleNames = roleBindings.value
    .filter(rb =>
      rb.spec?.subjects?.some(
        subject => subject.name === serviceAccount && subject.kind === 'ServiceAccount'
      )
    )
    .map(rb => rb.spec?.roleRef.name)
  return roles.value.filter(role => roleNames.includes(role.metadata?.name ?? ''))
}

const permissionsOfRole = (role: string) => {
  const _role = roles.value.find(r => r.metadata?.name === role)
  return _role?.spec?.permissions ?? []
}

const permissionsOfUser = async (user: string) => {
  const roles = await rolesOfUser(user)
  return [...new Set(roles.map(role => role.spec?.permissions).flat())]
}

const permissionsOfServiceAccount = async (serviceAccount: string) => {
  const roles = await rolesOfServiceAccount(serviceAccount)
  return [...new Set(roles.map(role => role.spec?.permissions).flat())]
}

export const useRbacV2 = () => {
  return {
    rbacV2Enabled,
    rbacV2Updating,
    authorize,
    roles,
    clusterRoles,
    roleBindings,
    getRoles,
    getRoleBindings,
    rolesOfUser,
    rolesOfServiceAccount,
    permissionsOfUser,
    permissionsOfServiceAccount,
    permissionsOfRole,
    BillingCustomerportalrequestsAlter,
    BillingCustomerportalrequestsCreate,
    BillingCustomerportalrequestsDelete,
    BillingCustomerportalrequestsDescribe,
    BillingPaymentintentsDescribe,
    BillingPaymentintentsCreate,
    BillingPaymentintentsDelete,
    BillingPaymentintentsAlter,
    BillingProductsDescribe,
    BillingProductsCreate,
    BillingProductsDelete,
    BillingProductsAlter,
    BillingSetupintentsDescribe,
    BillingSetupintentsCreate,
    BillingSetupintentsDelete,
    BillingSetupintentsAlter,
    BillingSubscriptionintentsAlter,
    BillingSubscriptionsDescribe,
    CloudApikeysDelete,
    CloudApikeysDescribe,
    CloudBookkeepersetoptionsDescribe,
    CloudCloudconnectionsAlter,
    CloudCloudconnectionsCreate,
    CloudCloudconnectionsDelete,
    CloudCloudconnectionsDescribe,
    CloudCloudenvironmentsAlter,
    CloudCloudenvironmentsCreate,
    CloudCloudenvironmentsDelete,
    CloudCloudenvironmentsDescribe,
    CloudIdentitypoolsAlter,
    CloudIdentitypoolsCreate,
    CloudIdentitypoolsDelete,
    CloudIdentitypoolsDescribe,
    CloudOidcprovidersAlter,
    CloudOidcprovidersCreate,
    CloudOidcprovidersDelete,
    CloudOidcprovidersDescribe,
    CloudPoolmembersDescribe,
    CloudPooloptionsDescribe,
    CloudPoolsDescribe,
    CloudPulsarclustersAlter,
    CloudPulsarclustersCreate,
    CloudPulsarclustersDelete,
    CloudPulsarclustersDescribe,
    CloudPulsargatewaysAlter,
    CloudPulsargatewaysCreate,
    CloudPulsargatewaysDelete,
    CloudPulsargatewaysDescribe,
    CloudPulsarinstancesAlter,
    CloudPulsarinstancesCreate,
    CloudPulsarinstancesDelete,
    CloudPulsarinstancesDescribe,
    CloudRolebindingsAlter,
    CloudRolebindingsCreate,
    CloudRolebindingsDelete,
    CloudRolebindingsDescribe,
    CloudRolesAlter,
    CloudRolesCreate,
    CloudRolesDelete,
    CloudRolesDescribe,
    CloudSecretsAlter,
    CloudSecretsCreate,
    CloudSecretsDelete,
    CloudSecretsDescribe,
    CloudServiceaccountbindingsAlter,
    CloudServiceaccountbindingsCreate,
    CloudServiceaccountbindingsDelete,
    CloudServiceaccountbindingsDescribe,
    CloudServiceaccountsAlter,
    CloudServiceaccountsCreate,
    CloudServiceaccountsDelete,
    CloudServiceaccountsDescribe,
    CloudUsersAlter,
    CloudUsersCreate,
    CloudUsersDelete,
    CloudUsersDescribe,
    CloudVolumesAlter,
    CloudVolumesCreate,
    CloudVolumesDelete,
    CloudVolumesDescribe,
    CloudCatalogsAlter,
    CloudCatalogsCreate,
    CloudCatalogsDelete,
    CloudCatalogsDescribe,
    CloudZookeepersetoptionsDescribe,
    PulsarAllAdmin,
    PulsarTenantsDescribe,
    PulsarTenantsAlter,
    PulsarTenantsCreate,
    PulsarTenantsDelete,
    PulsarNamespacesDescribe,
    PulsarNamespacesAlter,
    PulsarNamespacesCreate,
    PulsarNamespacesDelete,
    PulsarTopicsDescribe,
    PulsarTopicsAlter,
    PulsarTopicsCreate,
    PulsarTopicsDelete,
    PulsarSubscriptionsDescribe,
    PulsarSubscriptionsAlter,
    PulsarSubscriptionsCreate,
    PulsarSubscriptionsDelete,
    PulsarMessagesProduce,
    PulsarMessagesConsume,
    PulsarPoliciesDescribe,
    PulsarPoliciesAlter,
    PulsarFunctionsDescribe,
    PulsarFunctionsAlter,
    PulsarFunctionsCreate,
    PulsarFunctionsDelete,
    PulsarSinksDescribe,
    PulsarSinksAlter,
    PulsarSinksCreate,
    PulsarSinksDelete,
    PulsarSourcesDescribe,
    PulsarSourcesAlter,
    PulsarSourcesCreate,
    PulsarSourcesDelete,
    PulsarClustersDescribe,
    PulsarClustersAlter,
    PulsarClustersCreate,
    PulsarClustersDelete,
    PulsarBrokersDescribe,
    PulsarBrokersAlter,
    PulsarBrokersCreate,
    PulsarBrokersDelete,
    KsnSourcesDescribe,
    KsnSourcesAlter,
    KsnSourcesCreate,
    KsnSourcesDelete,
    KsnSinksDescribe,
    KsnSinksAlter,
    KsnSinksCreate,
    KsnSinksDelete,
    CloudMetricsPulsarQuery,
    CloudMetricsPulsarExport,
    CloudMetricsFunctionExport,
    CloudMetricsSourceExport,
    CloudMetricsSinkExport,
    CloudMetricsKafkaConnectExport,
    CloudMetricsHealthExport,
    CloudMetricsBillingQuery,
    CloudMetricsSubscriptionStripeQuery,
    CloudMetricsUsageStripQuery,
    CloudMetricsConsumptionStripeQuery,
    CloudMetricsConsumptionSugerQuery,
    CloudMetricsCostStripeQuery,
    CloudMetricsCostSugerQuery,
    CloudMetricsTermSugerQuery,
    CloudUnilinkAlter,
    CloudUnilinkCreate,
    CloudUnilinkDelete,
    CloudUnilinkDescribe
  }
}
